import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import TipsHeader from "components/Headers/TipsHeader.js";
import Footer from "components/Footers/Footer.js";

function Tips() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <TipsHeader />
        <div className="section">
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title">If you are foolish enough to embark upon this journey or one similar, here are some suggestions:</h3>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">Packing</h3>
                    <p className="description text-left">We found the lighter you can travel, the better. Think about packing as if it were you and your motorcycle doing a long backpacking trip together. For the person, carry the necessities in terms of clothes, camping gear (tent, sleeping bag, sleeping pad, etc.), and personal effects. We ended up bringing more then we needed and gave away or discarded a large quantity of gear over the first couple of weeks. We also carried a very small but strong cook stove, 1 small pot, 2 bowls, and 2 forks, and 1 sponge that was very dirty by the end of the trip - just enough to make simple meals. Remember - the less you have, the easier it is to pack, the lighter your bike is, the easier it is to ride, and the less the motorcycle wears.</p>
                    <p className="description text-left">For the motorcycle, we tried to carry the basics we thought we needed in terms of tools and spare parts. Shipping things in to Africa is generally a miserable endeavor, at least in our limited experience and the horror stories we heard. That being said, it is also not practical to carry an entire bike in spare parts. A good balance can be struck by ensuring the bike is in great condition before leaving and then thinking about what things will most likely wear out and where or how replacements could be obtained. Try to avoid bringing a bike that may have a major breakdown - there is almost nowhere to fix complex problems and breaking down in the wrong place puts you at risk.</p>
          				  <p className="description text-left">We were not the most experienced motorcycle travelers, or even motorcyclists, before we began the trip. We obtained our licenses through a weekend safety course in the middle of October, bought our bikes in the beginning of November, and set off on our in trip the middle of February. That gave us some time to become accustomed to motorcycling, but that experience was mostly limited to short urban commuting trips. Had we had more time and had it not been winter in Boston, we might have gone for a test trip for a few days. That would have given us a chance to appreciate just how sore one will likely get after four and half months in the saddle, how much distance can realistically be traveled in a day, as well as a better sense of how to pack and travel on the bikes.</p>
          				  <p className="description text-left">Spare parts we brought included: one new rear tire, one rear sprocket, one front sprocket, one chain, air filter, fuel filter/pressure regulator unit, front and back inner tubes, brake and clutch levers, oil filter, crush washers, clutch cable, headlight bulb, and spark plugs. The decision to bring a spare rear tire was a good one. The front lasted us the entire trip, if barely, and the rear would not have.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="10">
                  <h3 className="title text-left">Tools</h3>
                  <p className="description text-left">A customized socket set, crescent wrench, assorted wrenches, pliers, large flat screwdriver, a screw driver with interchangeable heads, a couple hacksaw blades, a file, vice-grips, 12v mini air compressor, tire gauge, multiple Leathermans, tire valve key, tire levers, tire patch kit, baby powder, duct tape, black electrical tape, rope, JB weld, small tube of never-seize, small tube of silicone, crazy-glue, chain lube, tie-wraps, and a few assorted hose clamps.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="10">
                  <h3 className="title text-left">Bike Modifications</h3>
                  <p className="description text-left">For the most part modifications were minimal. We both installed engine guards and outfitted standard Touratech racks to carry panniers. GPS units and power cords were wired into the bikes. We did not modify the 4.7 gallon fuel tanks and instead carried extra fuel in bottles when it was necessary (admittedly, we almost ran out of fuel quite a few times even though we never actually did). We changed out the lead-acid batteries for sealed gel batteries (much better, particularly when you are dropping your bike in the Congo or in the garage before you leave). We also changed the tires to Continental TKC 80 on/off road tires.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="10">
                  <h3 className="title text-left">Mechanical Issues</h3>
                  <p className="description text-left">The radiator on the f650gs Dakar started to leak in Western Sahara (southern Morocco). I fixed it twice with JB weld and a coke can and though the second repair seemed to hold, I still opted on having a new radiator shipped into Dakar, Senegal. The shipping experience was a nightmare, mostly because it took a full day of haggling and dishing out money to get the item out of customs. Were I to do it over again I might have just kept on repairing the leaking one until I could find a shop I trusted enough to weld it.</p>
                  <p className="description text-left">Both bikes at various times had trouble managing fuel or digesting the fuel in Africa. One odd feature of the f650gs is that they have a single unit for the pressure regulator/fuel filter. If I were to do the trip over again I would probably install a cleanable (take-apart) fuel filter between the gas tank and the pressure regulator/fuel filter unit.</p>
          			  <p className="description text-left">Our rear sprockets wore a lot faster than we anticipated. This was mostly due to the sandy, dirty, and generally tough road conditions we often found ourselves encountering. However, we also most likely did not properly care for our chains and sprockets sufficiently at the beginning of the trip. The front sprockets on both bikes lasted the entire trip, but the rear sprockets were changed out in Namibia. The chains also wore faster than expected.</p>
        				  <p className="description text-left">We were fortunate to have a friend flying in from the States to Windhoek, Namibia, who brought not only new rear sprockets, but also front and back brake pads for both bikes. Though perhaps not essential, it is nice to be able to stop when you want to.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="10">
                  <h3 className="title text-left">Paperwork</h3>
                  <p className="description text-left">Having organized paperwork can save you ample headache and time. Make sure you have the following valid and accessible for the 500+ police checkpoints you will have to stop at:</p>
                    <ul>
            					<li className="description text-left">Drivers License (normal and international)</li>
            					<li className="description text-left">Registration</li>
            					<li className="description text-left">Vehicle Insurance (we got away with using our US insurance in many countries - sometimes you need to buy the local insurance: Mauritania as well as Senegal, Mali, Burkina Faso. It is unclear if you need it in more places, but we never had a problem)</li>
            					<li className="description text-left">Passport</li>
            					<li className="description text-left">Vaccination Card</li>
            				</ul>
                  <p className="description text-left">At borders, the process is simple - get your duane stamped (Carnet Des Passages), get your passport stamped, and get out of there. Borders in Africa are simpler than in the US or the UK - avoid any extra stops unless you enjoy hassle and corruption.</p>
          				<p className="description text-left">Carnet de passage en douane - a document for your car / motorcycle that allows you to bring it into a country without paying import duty - we got ours from the Canadian Automobile Association. Make sure it lists Nigeria on the back!</p>
          				<p className="description text-left">For visas, ensure you have plenty of high quality passport photos. Plan on at least 2 per visa. Also, have copies of everything - lots! PDF all documents and store them online in case you lose anything. For letters of introduction, hotel reservations go a long way.</p>
          				<p className="description text-left">Getting all of the visas along the way is a bit of an art form. Please email us if you find yourself stumped.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="10">
                  <h3 className="title text-left">Visas and Borders</h3>
                  <p className="description text-left">Knowing beforehand what is required at a border can actually make some of the borders almost pleasant. Here are some helpful hints from our experience (Visa fees vary over time):</p>
                  <ul class="visas">
                    <li className="description text-left"><b>Morocco:</b> No visa necessary for US citizens. Carnet does not work in Morocco, so they do their own Duane paperwork. Police puts a special number in your passport as a separate stop. Had to pay up about 1 Euro for a "guide" through the process (probably did not need but it was our first border and we didn't know how to avoid. Plus he helped us cut the gigantic line). Took 1.5 hours total. Exit was simple and no fees.</li>
                    <li className="description text-left"><b>Mauritania:</b> Visa in Rabat - $20ish and took 1 day. No man's land before the border goes on for about 8km. Avoid the guides that offer their services - the route is easy to find. Duane and passport control buildings are hard to find. They tend open sporadically and do all passports present at the same time. Remember to buy insurance for $6 as the first policeman will check. Took 3 hours total. Exit at Diama was easy and no fees. Oh yes, if you attempt to cross at Rosso prepare for one of the worst borders in Africa, or the world.</li>
                    <li className="description text-left"><b>Senegal:</b> No visa necessary for US citizens. Entry at Diama was easy. Bridge fee is $10 - impossible to avoid. No other fees. Took 1 hour. Watch for the corrupt police officer just after St Louis. Exit into Mali is almost unguarded, and passport control is before the border. We entered Mali and had to go back to look for it. No fees and took 1 hour total.</li>
                    <li className="description text-left"><b>Mali:</b> Visa in Rabat - $30ish and took 1 day. Simple to find Duane and passport control. No fees - 1 hour total. Exit into Burkina again was difficult to find correct buildings</li>
                    <li className="description text-left"><b>Burkina Faso:</b> Visa at border. CFA 30,000 total fee per person. Should come out to CFA 10,000 but we could not remember the details and they took advantage. Alternatively, one can get a Visa Touristique Entente which would covers Burkina, Cote D'Ivoire, Togo, Benin and Niger. Took 1 hour. Exit to Ghana was very simple - no fees and 1 hour.</li>
                    <li className="description text-left"><b>Ghana:</b> Visa in Rabat - $50ish and took 2 days. Border was simple - they have computers and everything. We tried exiting near Wli Falls, but did not have a visa to Togo and they only issue them at the main borders. Tried again at Aflao - Chaotic and lots of hassle. No fees and took 1 hour.</li>
                    <li className="description text-left"><b>Togo:</b> Visa at border - CFA 10,000 for transit visa. Lots of hassle, but no other fees. Took 1 hour. Exit to Benin was pretty quiet. Easy Duane-Passport-Passport-Duane go!</li>
                    <li className="description text-left"><b>Benin:</b> Visa in US - we could have purchased at the border, but was very helpful for getting the Mali and Ghana visas as it could be used for proof of onward travel. Entry to was straightforward. No fees- 15 mins. Exit near Ketou was very friendly, although the duane station is about 11km before the border and unmarked. We missed it and had to go back - it is in Ketou. Passports are near the unmanned border.</li>
                    <li className="description text-left"><b>Nigeria:</b> Visa in Accra- tried unsuccessfully in Rabat and Dakar - $100, two days. Letter of introduction is hugely helpful. The border near Ketou is unmanned - had to find passport control and the duane station. Was pleasant and took 1 hour. Exit into Cameroon near Ikom was straightforward. Took 1 hour.</li>
                    <li className="description text-left"><b>Cameroon:</b> Visa in Dakar - $60, one day. Entry and exit were straightforward. No fees.</li>
                    <li className="description text-left"><b>Gabon:</b> Visa in Yaounde -; $100, two days - total pain and lots of waiting at the embassy. Entry was simple, less than one hour. Exit formalities are located well before Congo border before Leconi. No fees.</li>
                    <li className="description text-left"><b>Republic of Congo:</b> Visa in Abuja - $100, one day. Entry via Franceville road is found at the first bamboo hut after 30km of bad road. Duane is another 40km away. No fees. Exit in Brazzaville is confusing, although the police helped us through it.</li>
                    <li className="description text-left"><b>Democratic Republic of Congo:</b> Visa in Abuja - $110, one day. They make you fill in the visa application outside of the building. Entry in Kinshasa is chaotic - $20 fee for "disinfecting" the bikes. See blog for more information. Exit in Angola through Luvo is slow, but straightforward. No fees.
                    </li>
                    <li className="description text-left"><b>Angola:</b> Visa in Abuja - $100, one day, multiple entry visa for 5 days. Difficult visa to get - we were lucky. Entry in Luvo was chaotic and took 3 hours. No fees. Namibia exit was straightforward - one hour, no fees.</li>
                    <li className="description text-left"><b>Namibia:</b> No visa necessary for US citizens. $35 road tax total. Entry and exit were simple.</li>
                    <li className="description text-left"><b>Botswana:</b> No visa necessary for US citizens. $60 road tax each. Entry and exit were simple.</li>
                    <li className="description text-left"><b>Zambia:</b> No visa necessary for US citizens. $15 Carbon tax each - lots of other taxes although you do not need to pay them. Exit the checked to see that we had the Carbon tax.</li>
                    <li className="description text-left"><b>Zimbabwe:</b> Visa upon arrival for $25 each. Road tax and carbon tax at $20 each. Lots of other taxes although you do not need to pay.</li>
                    <li className="description text-left"><b>South Africa:</b> No visa necessary for US citizens. Simple entry.</li>
                    <li className="description text-left"><b>Lesotho:</b> No visa necessary for US citizens. Simple entry and exit.</li>
                  </ul>

                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="10">
                  <h3 className="title text-left">Shipping</h3>
                  <p className="description text-left">Greg from <a href="https://www.shipmybike.com">www.shipmybike.com</a> was extremely helpful shipping the bikes from New York to Madrid. It took 8 days in total, the bikes were efficiently tracked, and they arrived on time.</p>
                  <p className="description text-left">Reva from AirTransit at the Cape Town airport Cargo was also extremely helpful shipping the bikes from Cape Town to New York. The trip for the bikes took 4 days.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="10">
                  <h3 className="title">Good luck!  - Justus and Bash</h3>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Tips;
