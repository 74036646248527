import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

function Villages() {
  return (
    <>
      <div className="section"
      data-background-color="dark-blue">
        <div className="about-description text-center">
          <div className="features-3">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto" md="10">
                  <h2 className="title">North South Venture proudly supports Millennium Villages Project</h2>
                  <h5 className="description text-justify">
                  The principle reason we have embarked upon this
            			venture is to better understand West Africa and the challenges that
            			the inhabitants face. We hope that this trip is merely the beginning
            			of a long-term effort to contribute significantly to many of the
            			areas we visit. We have identified the MVP as an effective
            			organization that has initiated powerful positive changes in these
            			areas as well as Africa as a whole. Any help we can give to the
            			Millennium Villages will not only benefit the lives of the families
            			within the communities, but also their region, their countries, and
            			frankly the world itself.
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default Villages;
