import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/spain/DSC00015.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00018.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00021.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00042.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00048.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00056.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00064.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00072.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00081.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00085.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00087.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00092.JPG"),
    altText: "North South Venture Spain"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/spain/DSC00101.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00111.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00114.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00120.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00123.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/DSC00128.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/IMG_3455.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/IMG_3587.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/IMG_3594.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/IMG_3605.JPG"),
    altText: "North South Venture Spain"
  },
  {
    src: require("assets/img/countries/spain/Madrid_048.jpg"),
    altText: "North South Venture Spain"
  }

];

function Spain() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/spain/DSC00114.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Spain</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/South Africa"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> South Africa
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Morocco"
                  >
                  Morocco <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">February 22nd, 2010</h3>
                    <p className="description text-left">
                    We have arrived in Madrid. Finally! It feels fantastic. The motorcycles arrived without incident at the Madrid airport on Tuesday - ahead of schedule! Unfortunately the customs officers in New York had drained our gas tanks and our first few miles in Spain were some of the most stressful as we desperately searched for a gas station while our bikes sputtered along the highway.
                    </p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">February 25th, 2010</h3>
                    <p className="description text-left">
                    From Madrid we ventured to Granada. It was our first long ride and it was beautiful, although tiring. It has been very windy in Spain and not a weather condition we are used to riding in. Bash looked very odd leaning at a 10 degree angle in front of me all day to compensate for this strong force. Though a long day, I am excited to report that all of our gear worked well and that we are riding strongly. A few more training days in Spain should put us in great shape for Africa.
                    </p>
                    <p className="description text-left">
                    Today we are off to Seville. Tomorrow to Cadiz. The next to Africa! Yesterday, Bash and I battled wind, rain, and speedy Spanish drivers. We stopped for coffee at one point and the motorcycles almost blew over. Hopefully the weather does not further deteriorate on the way to the coast. At least we missed the 160km winds in the North.
                    </p>
                  </Col>
                </Row>
                <Row>
                <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                  <CarouselIndicators
                    items={items2}
                    activeIndex={activeIndex2}
                    onClickHandler={goToIndex2}
                  />
                  {items2.map(item2 => {
                    return (
                      <CarouselItem
                        onExiting={onExiting2}
                        onExited={onExited2}
                        key={item2.src}
                      >
                        <img src={item2.src} alt={item2.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item2.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">February 26th, 2010</h3>
                    <p className="description text-left">Today is a drizzly sleepy day in Seville - one of the most beautiful cities we have visited in Spain. Tomorrow we will venture to Tarifa to board the ferry to Tangier. The sea-reach of the Mediterranean stretches before us like the beginning of an interminable waterway. Landing ashore will mark the official start of the Africa trip.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/South Africa"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> South Africa
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Morocco"
                      >
                      Morocco <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Spain;
