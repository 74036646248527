import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/botswana/DSC01993.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/DSC02005.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/DSC02009.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/DSC02062.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/DSC02068.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/DSC02086.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/DSC02092.JPG"),
    altText: "North South Venture Botswana"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/botswana/DSC02100.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/DSC02104.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/IMG_5237.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/IMG_5244.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/IMG_5262.JPG"),
    altText: "North South Venture Botswana"
  },
  {
    src: require("assets/img/countries/botswana/IMG_5275.JPG"),
    altText: "North South Venture Botswana"
  }
];

function Botswana() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/botswana/IMG_5258.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Botswana</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Namibia"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Namibia
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Zambia"
                  >
                  Zambia <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">June 4th, 2010</h3>
                    <p className="description text-left">It looks like a paradise, but it is the most treacherous desert in the world. The Kalahari. After the short rainy season there are many water holes, and even rivers. But after a few weeks, the water sinks away into the deep Kalahari sand. The water holes dry, and the rivers stop flowing. The grass fades to a beautiful blond color that offers excellent grazing. But for the next nine months, there will be no water to drink. So most of the animals move away, leaving the blond grass uneaten. Humans avoid the Kalahari like the plague because man must have water. So the beautiful landscapes are devoid of people.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">Bash and I entered Botswana at Charles Hill and quickly drove toward Ghanzi along of the edge of the Kalahari. We found a deserted brush area off the main road for a night of bush camping. The temperature dropped to almost 35 degrees as my hyenas yelped in the distance. I was very grateful that Charlotte had donated her warm sleeping bag as my summer bag was no match for this land. The following morning we continued our trip to Maun at the edge of the Okavanga Delta. The trip there was complete with the normal motorcycle concerns - this time a fuel shortage forced us to drive 60 km per hour most of the way to keep our RPMs low. Bash drafted behind me for better gas mileage.</p>
                    <p className="description text-left">As we were most interested in spending time in the delta, we arranged for a day trip on the water in a mokoro, or dugout canoe. With our guide whose name we were unable to pronounce paddling at the stern, we crept along quietly through the lily pads and partly submerged grass. The guide was careful to avoid the deeper sections where the hippos frequently feed and sometimes remain submerged and unnoticed until a canoe, such as ours, disturbs their meal.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
          					<p className="description text-left">After a few hours on the water and numerous encounters with spiders which jump off of the surrounding grass into our boat, our guide took us on a short game walk on one of the delta's many islands. While seeing animals out of our car window in Etosha was a magical experience, it was unique to see them on foot. We tiptoed through the brush trying to minimize our noise, hoping to see as much as possible. A troop of baboons wandered a few hundred feet ahead of us throughout most of the walk. Out in the distance a giraffe's head poked out from the brush next to a palm tree, a wildebeest stood idle, a herd of lechwe grazed, and warthogs continually spooked around us.</p>
                    <p className="description text-left">The Okavango Delta is produced by seasonal flooding. The Okavango river drains the summer (January-February) rainfall from the Angola highlands and the surge flows 1,200 km in one month. The waters then spread over the delta over the next four months (March-June). The high temperature of the delta causes rapid transpiration and evaporation, resulting in a cycle of rising and falling water level. The flood peaks between June and August, during Botswana's dry winter months, when the delta swells to three times its permanent size, attracting animals from miles around and creating one of Africa's greatest concentrations of wildlife. The delta is very flat, with less than 2 meters variation in height across its 15,000 square kilometers.</p>
          					<p className="description text-left">Tired from the day's excursion we went to bed early and prepared for the long drive to Kasane, near the Zambia border. Similar to other parts of Botswana, wildlife is abundant. At 4pm, Bash and I approached an elephant on the side of the road. Unsure of what would happen if we tried to pass we waited at a safe distance until it walked further away from the road before attempting to continue on. That night in our campground there were elephant footprints everywhere. The camp manager visited to explain that they had driven away most of the gigantic creatures and we should not worry. Throughout the night we could hear the boom of nearby flares as other establishments attempted to coerce the elephants back into the wild. The gods must have been crazy that night.</p>
          					<p className="description text-left">Oh yes - see if you can spot the giraffe and elephant in the photos above.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Namibia"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Namibia
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Zambia"
                      >
                      Zambia <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Botswana;
