import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/namibia/DSC00858.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC00859.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC00871.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC00913-1.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC00935-1.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC00941-1.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC00970-1.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC00990.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01016.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01075.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01744.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01767.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01778.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01798.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01840.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01863.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01869.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01889.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01900.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01909.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/DSC01973.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0121.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0138.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0169.JPG"),
    altText: "North South Venture Namibia"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/namibia/IMG_0479.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0617.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0717.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0837.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0850.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0868.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0943.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_0999.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_1001.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_1016.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_1252.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5001.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5009.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5010.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5014.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5033.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5115.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5144.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5193.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5209.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5211.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5220.JPG"),
    altText: "North South Venture Namibia"
  },
  {
    src: require("assets/img/countries/namibia/IMG_5233.JPG"),
    altText: "North South Venture Namibia"
  }
];

function Namibia() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/namibia/IMG_0943.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Namibia</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Angola"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Angola
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Botswana"
                  >
                  Botswana <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">May 24th, 2010</h3>
                    <p className="description text-left">After three months of traveling, we have arrived in Windhoek, Namibia.</p>
                    <p className="description text-left">A third? Yes- there is a third joining us tomorrow in Windhoek for our Namibian venture. I will leave this a mystery for now.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">June 4th, 2010</h3>
                    <p className="description text-left">After a few days rest in Windhoek at the Chameleon Guesthouse, we felt sufficiently recovered and headed off to explore more of the country - this time in a car. We first drove to the airport to pick up our friend Charlotte from Jackson Hole who flew in to join us for our Namibia venture.</p>
                    <p className="description text-left">As we were all extremely enthusiastic to see animals and to give Charlotte a grand introduction to Africa, our first stop was the AfriCat Foundation ranch north of Windhoek where we thought we would maximize our chances of seeing leopards and cheetahs. Oddly enough, after three months in Africa Bash and I have seen very few animals. The drive up to the lodge was spectacular and complete with kudu, gemsbok, springbok, and dafassa sightings, as well as many other creatures we were not able to identify. Although we were ecstatic about seeing so much wildlife, we began to wonder if there was any greater fence in place meant to keep some of the animals away from the lodge. More specifically, we were wondering where the fence was that was meant to keep the leopards and cheetahs out of our campsite that evening.</p>
          					<p className="description text-left">After we arrived, our Namibian guide Nigel led us two kilometers out into the bush into their "other land use" area to camp. As he said goodnight he showed us the panic button on the radio and commented, "Because, well, you never know. If a leopard does find you, whatever you do, don't run or scream. Otherwise, sleep well and see you at 6 am!" Anybody want to use the outdoor shower before dinner? I wanted to reply with David Huxley's quote from Bringing Up Baby, "When a man is wrestling a leopard in the middle of a pond, he's in no position to run!" but it did not seem appropriate. We established that "other land use" meant where the wild animals roam.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">By now Charlotte was convinced that we were secretly aiming to feed her to the nearest predator. Bash and I could hardly contain our giggling. I was not laughing when something large walked into my tent at 2 am. I did not sleep the rest of the night and almost every noise sounded like a leopard approaching to devour me. I curled up in the center of my nylon abode and was relieved I stored all of the dried meat in Bash's tent.</p>
          					<p className="description text-left">We awoke early in the morning to join Nigel for an "activity" including feeding one of the ranch leopards and looking at three of the cheetahs. Nigel also discussed the multiple difficulties facing predators in modern day Namibia. Namibia is home to approximately 25% of the world's cheetah population of which 90% live on farmland. As the predators hunt cattle on this farmland, the farmers frequently hunt these predators and have drastically reduced the population over the past 50 years. AfriCat serves as an intermediary between the animals and farmers by transporting troubled animals to either their ranch or other less populated areas around the country. The foundation also helps farmers improve their livestock management technique to better protect their herds against predator attacks and thus decreasing a farmer's need to kill problem animals.</p>
          					<p className="description text-left">Following our cat excursion we ventured north toward Etosha national park. We were singing along to our new Ladysmith Black CD when I cried stop! Giraffe! After slamming on the breaks, Bash went to mingle with his brethren animal. Before I go any further and you think we have all gone off the deep end, I will briefly explain. When bash and I first purchased our bikes, we spent days trying to name them after animals. Mine was red so I chose the Hyena. Bash's was blue, and given the rarity of blue mammals I decided that his was to be a giraffe - more to do with Bash's height than the appearance of the bike. You may notice the hyena and giraffe on the website. As a result, we were thrilled to finally see one.</p>
          					<p className="description text-left">We spent the next few days searching out animals on the edges of the Etosha Pan. Etosha is home to an estimated 2,500 giraffes, 6,000 zebras, 2,000 elephants, 300 rhinos, 350 lions, and at least 20,000 springbok. Bash used his Australian safari accent when animal spotting to enhance the experience. We were successful finding the majority of the animals we were looking for, except for lions. Actually Bash and I saw one on the road while Charlotte was scanning the guide book to aid in our search for a lion, so we felt obligated to find another one for her to see. As the days past, the situation became increasingly dire. Even the old, "Here kitty kitty," did not work. In a final attempt to outsmart the animal I perused the guide book and found that there was an AfriLeo Foundation close to the park which was similar to the leopard and cheetah ranch we had visited on the way up to Etosha. Our research paid off and the next morning we witnessed five grown lions feasting on their breakfast. Another success.</p>
                    <p className="description text-left">Satisfied with our safari animal count, we returned south to Windhoek to trade the car in for our bikes, do an afternoon of motorcycle mechanics using the parts that Charlotte had kindly brought for us and left for Swakopmund and the coast the next morning. Getting three people on two bikes proved to be challenging, but we after some repacking we were off again on our familiar transportation.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
                    <p className="description text-left">30 kilometers from the coast in Namibia the temperature dropped 20 degrees Fahrenheit and it became foggy. While our original plan was to camp near the beach, the cold weather dissuaded us and we checked into the Desert Sky Guesthouse near the center of town. While Bash and I pride ourselves on our brilliant Ramen cooking ability, we realized that others may prefer healthier dinner options. With Charlottes help, a lot of help, we or rather they managed to produce a delicious meal complete with pesto and vegetables. Would we turn over a new cooking leaf? We were optimistic.</p>
                    <p className="description text-left">There are multiple outdoor activities available around Swakopmund including kayaking, skydiving, hiking, sand-boarding, four-wheeling, and more. As Charlotte had not yet had an African desert experience, we drove through Walvis Bay and to Dune 7 for a hike. When Charlotte changed into her shorts and asked if the bikes were going to be safe because we would be gone for hours we were a little worried about what she was getting us into athletically. While motorcycling is mentally taxing, it is not the most aerobic of activities. When Bash and I reached the top of the first steep dune above the parking lot we were very proud of our effort until Charlotte asked if we were ready to climb the "dune over there." The "dune over there" could barely be seen in the distance and not wanting to seem like wimps after our motorcycle trip we agreed that we were both ready, just waiting for her to catch her breath. After a few hours of trying to keep up with her over the soft sand dunes, we safely ruled out any more physical exercise for the next few days.</p>
          					<p className="description text-left">Hobbling out of bed the next morning we decided that a visit to the seal colony at Cape Cross would be a safer activity for the day. Expecting only to see a few seals, we were a bit overwhelmed when we had our first look. Cape Cross is home to over 100,000 seals and all of them live concentrated on a small rocky point as well as the surrounding water. The smell and noise was unimaginable and captivating. The coast appeared to be moving as far as one could see. As it was getting late when we were finally finished watching all of our new friends, we found a brand new lodge nearby that allowed us to camp for free. As the dusk was falling, we read in a lofty drawing-room by the fire with long windows from floor to ceiling that were like luminous and bedraped columns. The bent gilt legs and backs of the furniture shone in indistinct curves. The sunset over the ocean and surf was unparallel. A wonderful way to spend our last night on the coast.</p>
                    <p className="description text-left">A few days later we said our goodbyes to Charlotte, sad to see her go after such a fun trip, and continued on our way to the Botswana border for another venture.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Angola"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Angola
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Botswana"
                      >
                      Botswana <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Namibia;
