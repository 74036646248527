import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/senegal/DSC00201.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00207.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00568.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00570-1.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00573.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00575.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00578-1.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00588-1.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00587-1.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00588-1.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00590.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00594.JPG"),
    altText: "North South Venture Senegal"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/senegal/DSC00595.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00596.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00598.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00603.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00608.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00612.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/DSC00615.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/IMG_4126.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/IMG_4134.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/IMG_4140.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/IMG_4149.JPG"),
    altText: "North South Venture Senegal"
  },
  {
    src: require("assets/img/countries/senegal/IMG_4153.jpg"),
    altText: "North South Venture Senegal"
  }
];

function Senegal() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/senegal/IMG_4153.jpg") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Senegal</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Mauritania"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Mauritania
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Mali"
                  >
                  Mali <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">March 24th, 2010</h3>
                    <p className="description text-left">
                    Sorry for so few blog posts in the last week - we have been riding a lot and have not had great access to internet.
                    </p>
                    <p className="description text-left">
                    We will post extended blog posts in Dakar, but we have traveled through Mauritania and are currently in Saint Louis, Senegal. Couldn't be nicer.
                    </p>
                    <p className="description text-left">
                    All is in good order - minus a slightly broken radiator that Bash was able to fix. Another is being sent to Dakar as we speak.
                    </p>
                    <p className="description text-left">
                    There has been a lot of excitement and we will write about it all in the next few days.
                    </p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">March 25th, 2010</h3>
                  	<p className="description text-left">Two days ago Bash and I participated in the World Championships of Customs Clearing. It is a somewhat obscure sport, but we thought we would try our luck. It is very big in Dakar.</p>
        						<p className="description text-left">We arrived at the airport at 1 pm ready to compete. The goal was to get Bash's radiator through customs as quickly as possible. At the starting gate we spoke with the primary judge. He asked us if we knew the rules. Ha ha! What rules? There are no rules. Okay ready? One, two, three, go!</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
                    <p className="description text-left">We ran to see the first man. Up stairs, down stairs, around the block, back to the first man. The judge called a time out for a xerox break. He also appointed us a referee (guide). On to find a typewriter, next to find a stamp. Timeout again! We need to pay a fee. Surprise, surprise.. Foul! No, no - all part of the game. You see - there are no rules.</p>
          					<p className="description text-left">We finished the first half of the game in about 2 hours. Then everyone slept for 1 hour as the sport is so exhausting.</p>
          					<p className="description text-left">The second half is faster and even more confusing. It involves about 30 separate people and 20 separate stamps. It is really, really fast paced. Bash and I thought we may place well when we eventually saw and unpacked the radiator, but no. The game was over and it was a draw - time for sudden death overtime.</p>
          					<p className="description text-left">So we ran back to many of the original 30 people. Stop - time to pay another "fee". Go - time to avoid seedy characters. The end is in sight again. No - foiled again. Back to the start. Up stairs, down stairs, around the block, back to the first man.</p>
          					<p className="description text-left">We passed the finish line in a little over 5 hours. No bad for our first competition. Our prize: a brand new radiator for Bash's bike!</p>
                    <p className="description text-left">Sadly, we will not be competing again next year - this sport is better left to the professionals.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Mauritania"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Mauritania
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Mali"
                      >
                      Mali <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Senegal;
