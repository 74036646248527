import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import HomepageHeader from "components/Headers/HomepageHeader.js";
import Footer from "components/Footers/Footer.js";

// sections for this page
import Components from "./homepage-sections/Components.js";
import Content from "./homepage-sections/Content.js";
import Villages from "./homepage-sections/Villages.js";
import BlogPosts from "./homepage-sections/BlogPosts.js";
import Disclaimer from "./homepage-sections/Disclaimer.js";

function Homepage() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function() {
        new Rellax(".rellax", {
          center: true
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <HomepageHeader />
        <Components />
        <Villages />
        <Content />
        <BlogPosts />
        <Disclaimer />
        <Footer/>
      </div>
    </>
  );
}

export default Homepage;
