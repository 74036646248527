/*!

=========================================================
* Now UI Kit PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages
import AboutUs from "views/nsv/AboutUs.js";
import Vision from "views/nsv/Vision.js";
import Journey from "views/nsv/Journey.js";
import Homepage from "views/Homepage.js";
import Tips from "views/nsv/Tips.js";
// countries
import Spain from "views/countries/Spain.js";
import Morocco from "views/countries/Morocco.js";
import Mauritania from "views/countries/Mauritania.js";
import Senegal from "views/countries/Senegal.js";
import Mali from "views/countries/Mali.js";
import BurkinaFaso from "views/countries/BurkinaFaso.js";
import Ghana from "views/countries/Ghana.js";
import Togo from "views/countries/Togo.js";
import Benin from "views/countries/Benin.js";
import Nigeria from "views/countries/Nigeria.js";
import Cameroon from "views/countries/Cameroon.js";
import Gabon from "views/countries/Gabon.js";
import Congo from "views/countries/Congo.js";
import DRC from "views/countries/DRC.js";
import Angola from "views/countries/Angola.js";
import Namibia from "views/countries/Namibia.js";
import Botswana from "views/countries/Botswana.js";
import Zambia from "views/countries/Zambia.js";
import Zimbabwe from "views/countries/Zimbabwe.js";
import SouthAfrica from "views/countries/SouthAfrica.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/about-us" render={props => <AboutUs {...props} />} />
      <Route path="/vision" render={props => <Vision {...props} />} />
      <Route path="/journey" render={props => <Journey {...props} />} />
      <Route path="/tips" render={props => <Tips {...props} />} />
      <Route path="/homepage" render={props => <Homepage {...props} />} />
      <Route path="/Spain" render={props => <Spain {...props} />} />
      <Route path="/Morocco" render={props => <Morocco {...props} />} />
      <Route path="/Mauritania" render={props => <Mauritania {...props} />} />
      <Route path="/Senegal" render={props => <Senegal {...props} />} />
      <Route path="/Mali" render={props => <Mali {...props} />} />
      <Route path="/Burkina Faso" render={props => <BurkinaFaso {...props} />} />
      <Route path="/Ghana" render={props => <Ghana {...props} />} />
      <Route path="/Togo" render={props => <Togo {...props} />} />
      <Route path="/Benin" render={props => <Benin {...props} />} />
      <Route path="/Nigeria" render={props => <Nigeria {...props} />} />
      <Route path="/Cameroon" render={props => <Cameroon {...props} />} />
      <Route path="/Gabon" render={props => <Gabon {...props} />} />
      <Route path="/Congo" render={props => <Congo {...props} />} />
      <Route path="/DRC" render={props => <DRC {...props} />} />
      <Route path="/Angola" render={props => <Angola {...props} />} />
      <Route path="/Namibia" render={props => <Namibia {...props} />} />
      <Route path="/Botswana" render={props => <Botswana {...props} />} />
      <Route path="/Zambia" render={props => <Zambia {...props} />} />
      <Route path="/Zimbabwe" render={props => <Zimbabwe {...props} />} />
      <Route path="/South Africa" render={props => <SouthAfrica {...props} />} />
      <Redirect to="/homepage" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
