import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/congo/DSC01542.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01544.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01552.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01564.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01568.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01570.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01574.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01581.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01583.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01588.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01590.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01591.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01592.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01593.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01594.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01596.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01598.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01599.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01602.JPG"),
    altText: "North South Venture Congo"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/congo/DSC01604.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01606.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01617.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01625.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01626.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC01627.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/IMG_4824.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/IMG_4830.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/IMG_4839.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/IMG_4845.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/IMG_4846.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/IMG_4848.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/IMG_4856.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC00656.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC00657.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC00658.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC00659.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC00660.JPG"),
    altText: "North South Venture Congo"
  },
  {
    src: require("assets/img/countries/congo/DSC00661.JPG"),
    altText: "North South Venture Congo"
  }
];

function Congo() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/congo/IMG_4839.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Republic of Congo</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Gabon"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Gabon
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/DRC"
                  >
                  DRC <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">May 12th, 2010</h3>
                    <p className="description text-left">Entering the Congo we were wanderers on prehistoric earth, on an earth that wore the aspect of an unknown planet. From our bush camp site the first night just over the border we could see a savage fire in the distance, flickers of lightning another direction, and the green rolling savannah on all sides stretching into the horizon. Soon after dark it began to rain lightly, then heavily, and then lightning began to strike around us. Out of my tent I saw a flash to the left. One, two, three, four, five, six seconds, and then the monstrous crackling of the thunder. Good, still more than a mile away. Suddenly another flash to the right of the tent. A four count this time. Any closer and we would have to dress in our rain gear and separate to avoid both being struck at the same time. It was 2:46 am. Time ticked slowly and the storm did not dissipate. We sat waiting, hoping for it to stop. 3:00 am became 4:00 am as we deliriously willed the storm to ease. It was not sleep - it seemed unnatural, like a state of trance. 4:00 am became 5:45 am. A few minutes past without flashes of light, then a few more. The storm was over.</p>
                    <p className="description text-left">Bash poked his head out of his tent to see if I had survived and yelled, "Justus, you have to come out here!" I unzipped my tent and looked outside expecting the worst. In front of us was a vivid rainbow stretching over the smoke and fog accumulated in the valleys. The earth seemed unearthly. The view was stunning. Such was our introduction to the Congo.</p>
          					<p className="description text-left">The first 30 km of road consisted of rutted soft sand. It was the most difficult stretch of road we had encountered thus far and progress was slow. Some sections seemed impossible. Once one enters a long truck rut one has to follow it to its conclusion and often that conclusion is around a bend, and often that conclusion is ugly. We ran with the bikes, wrestled, pushed, kicked, cursed, but managed to make it out of each torturous rut.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">By 10:00 am we arrived at the little bamboo immigration hut on the Congo side of the border. The immigration official was very jolly and quickly stamped our passports while we sat staring into space in some uncomfortable chairs. Outside the hut was a beautiful stretch of pavement and encouraged that we had passed the difficult stretch of road, once legally stamped into the Congo we began to clean the bikes off and tidy the chains. We were mistaken - the difficult part was yet to come. The beautiful stretch of road ended past the first corner and the road fell off into deeper sand and mud. We continued 40 more kilometers at an even slower pace than before. Some of the ruts were four or five feet deep, but again we were able to make it through each one successfully.</p>
                    <p className="description text-left">At 1:00 pm we arrived at the duane station where to get our carnets signed. The man spoke great English and told us how far the distances were between the towns we would pass on the way to Brazzaville. He said that we had another 160 km of dirt road until we would finally hit pavement. Soon after we left our new friend the road deteriorated even further. The rain from the night before had filled in many of the ruts with a vile green foaming water. Becoming quickly discouraged after a few crashes into this sludge and surrounding soft mud we met three men on mopeds who seemed to know the best routes. Their very existence so far away from anything was improbable, inexplicable, and altogether bewildering. We were so desperate for help that it did not bother us that two out of the three had shot guns hanging on their backs. For the next fifteen minutes they led us through little sandy villages and around some of the worst submerged stretches of road. Finally we arrived at a stretch that looked far too deep to pass. The men were able to push their mopeds up the surrounding bank, but our bikes were too heavy. After wading through the swamp to assess the depth, Bash went for it. With a 20 meter runway before the water he gathered some bold speed, created a brilliant splash before swerving radically from side to side and crashing into the dishwater. I cheered from the sidelines before scrambling down to help him. Luckily his bike turned on again and he was able to finish the section without another incident.</p>
                    <p className="description text-left">So the day wore on, hour after hour after hour. By 4 pm we had reached a section of road the Chinese were in the process of building and our progress sped up. At 5:30 we reached a Boudji, a town with electricity and we were able to buy some cold water, a soda, and some bread. With little daylight left we hurried out of town to find another bush camp before dark. While eating a pasta dinner a continuous shower of small flies streamed upon the headlamps, upon the cloth, upon our hands and faces. At night it was very quiet there. The roll of drums behind the curtain of trees remained sustained faintly, as if hovering in the air high over our heads, till the first break of day. Though the village was far away, using the words of another overlander describing their camping experience in this section - we imagined they were dancing around a big bowl of boiling water getting ready to cook us.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
          					<p className="description text-left">On our second day in the Congo we awoke at dawn and penetrated deeper and deeper into the heart of darkness. The roads improved until we eventually met pavement. Immediately, Bash had a flat tire. Quickly changing the tube we were back on the road frantically driving towards Brazzaville. The road was in good shape, if not rather potholed at times and our progress was rapid. By 5:30 we had reached the outskirts of Brazzaville. The entry road was like no place I had ever seen. Crocodile skins hanging on sticks, teens fighting on the side of the road but stopping to wave as we drove by, and the great Congo river in the distance sitting below another fierce lightning storm. After aggressively weaving through traffic we found the Hippocampe Hotel just before dark.</p>
                  	<p className="description text-left">We were so tired from the journey from Gabon, or more accurately Yaounde, we decided to stay an extra day at the Hippocampe to rest before the attempting the ferry crossing to Kinshasa. We were also eager to avoid Kinshasa on Saturday afternoon. We found out later on Saturday that the ferry did not run on Sundays and we would have to wait yet another day for our crossing. Monday morning it was then. We spent our two days doing laundry, exploring Brazzaville, going over our ferry crossing plan, route research, and socializing with our various new friends at the hotel, including two Portuguese motorcyclists who showed up Saturday afternoon. As one did not speak English, and we did not speak Portuguese, Bash spoke Spanish but no French, and I a little French but no Spanish, our dinner conversations fluctuated rapidly between four different languages. After a fun few relaxing days in Brazzaville Monday morning came and it was time for our crossing.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Gabon"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Gabon
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/DRC"
                      >
                      DRC <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Congo;
