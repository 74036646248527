import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/zambia/DSC01092.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/DSC01093.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/DSC01094.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/DSC01098.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/DSC01113.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/DSC01121.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/DSC02125.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_1747.jpg"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_1755.jpg"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_1762.jpg"),
    altText: "North South Venture Zambia"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/zambia/IMG_1883.jpg"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_5294.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_5335.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_7870.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_7878.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_9449.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_9474.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_9533.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_9563.JPG"),
    altText: "North South Venture Zambia"
  },
  {
    src: require("assets/img/countries/zambia/IMG_9592.JPG"),
    altText: "North South Venture Zambia"
  }
];

function Zambia() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/zambia/IMG_9563.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Zambia</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Namibia"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Namibia
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Zimbabwe"
                  >
                  Zimbabwe <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">June 11th, 2010</h3>
                    <p className="description text-left">While not on the original agenda, as we were ahead of schedule we decided to venture into Zambia with two simple objectives: to see Victoria Falls and to raft the mighty Zambezi river. The stretch of river starting immediately below Victoria Falls is classified as Class V whitewater and is known as one of the wildest whitewater runs in the world.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">Upon arrival in Livingston achieving our first objective, seeing Victoria Falls, was easy as it was flood season. In fact, it was difficult not to see the Falls due to the immense cloud of spray forming from the incredible quantity of water flowing over the 300 foot drop and exploded into the small gorge below. Considered one of the seven natural wonders of the world, the sight was phenomenal.</p>
                    <p className="description text-left">While aesthetically pleasing, the current size of the river was not as as conducive to rafting. None of the rafting companies were currently running trips. Not to be dissuaded we looked into renting rafts and kayaks as an alternative. Bash was not as enthusiastic about this idea; nor were the rental companies.</p>
          					<p className="description text-left">Although slightly discouraged, we were not prepared to give up. With a little luck, the small guesthouse that hosted our morning yoga class heard that one of the nearby rafting companies was going to run their first trip of the year four days later, a "test run" for the season. We told them to sign us up.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">June 12th, 2010</h3>
          					<p className="description text-left">With several days to fill before our rafting venture, we had many activity options in or nearby Livingston. Bash found the old Livingston Golf Club which had a fitness center and an excellent deck for coffee. We spent two mornings there.</p>
                  	<p className="description text-left">At the Jollyboys guesthouse where we were staying, we ran into our Swiss friend Dominik. After ample time reminiscing about our dear night on Mount Cameroon with the rats, Dominik mentioned that he had signed up to go Bungee jumping. Eager to watch the excitement, we all headed over to the nearby bridge. Somehow in all of the enthusiasm my name also ended up on the participant list.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">June 14th, 2010</h3>
          					<p className="description text-left">Finally, the day of rafting came. Our guide, Potato, picked us up at 7:30 am and drove us as well as the six other anxious passengers to the edge of the river.</p>
                    <p className="description text-left">While the normal liability waiver in the United States for Class V whitewater is a lengthy dull document with many places to sign and initial, liability works differently in Africa - there is none. We all scribbled our names on a piece of paper stating that this was a bad idea and declared the process complete. Okay - ready to go rafting? Great - here is your wetsuit, paddle, and helmet. There is the path down to the river - see you at the boat!</p>
          					<p className="description text-left">Potato turned out to be a great guide and we navigated the river without much difficulty. The river during flood season is actually not as exciting as most of the rapids are too far below the surface to produce dynamic hydraulic features. The waves were huge and powerful though, and the trip was a fantastic ride.</p>
          					<p className="description text-left">Satisfied that we had conquered the mighty Zambezi, we once again headed North. This time, for the Zimbabwe border.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Botswana"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Botswana
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Zimbabwe"
                      >
                      Zimbabwe <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Zambia;
