/*eslint-disable*/
import React from "react";

// reactstrap components

// core components

function HomepageHeader() {
  return (
    <>
      <div className="page-header clear-filter">
        <div className="rellax-header rellax-header-sky" data-rellax-speed="-4">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/home-page/DSC00705-4.jpg") + ")"
            }}
          ></div>
        </div>
        <div className="rellax-text-container rellax-text">
          <h1 className="h1-seo" data-rellax-speed="-1">
            North South Venture
          </h1>
        </div>
        <h3 className="h3-description rellax-text" data-rellax-speed="-1">
        Morocco to Cape Town on a motorcycle
        </h3>
        <h6
          className="category category-absolute rellax-text"
          data-rellax-speed="-1"
        >
        Down the West coast of Africa in 2010
        </h6>
      </div>
    </>
  );
}

export default HomepageHeader;
