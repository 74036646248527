import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/burkinafaso/DSC00736.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00739.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00742.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00750.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00753.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00754.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00759.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00763.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00765.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00767.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00774.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00775.JPG"),
    altText: "North South Venture Burkina Faso"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/burkinafaso/DSC00776.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00778.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00781.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00791.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00792.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00797.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00800.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00801.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00802.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00804.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/DSC00806.JPG"),
    altText: "North South Venture Burkina Faso"
  },
  {
    src: require("assets/img/countries/burkinafaso/IMG_4345.JPG"),
    altText: "North South Venture Burkina Faso"
  }
];

function BurkinaFaso() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/burkinafaso/DSC00781.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Burkina Faso</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Mali"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Mali
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Ghana"
                  >
                  Ghana <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">April 7th, 2010</h3>
                    <p className="description text-left">
                    We did not know much about Burkina Faso before we arrived. We did know that the country has the lowest adult literacy rate in the world at 23.6%, slightly behind Mali at 24.0%, and also more than 81% of the citizens live on less than $2 a day. Both shocking statistics.
                    </p>
                    <p className="description text-left">What we noticed immediately was that similarly to Mali the people were incredibly friendly. After each border crossing Bash and I usually pull over after about 10 minutes to regroup and de-stress. While we were standing on the side of the road in the shade, each passerby on foot or a bike would say hello, come over and shake our hands, tell us they loved the bikes, and then continue on their way. Very friendly. We liked Burkina Faso from the start.</p>
      						  <p className="description text-left">We also noticed that similarly to Mali, police checkpoints did not really apply to motorcycles. We could just drive right on through. There were generally small gates or paths around the road blocks and we just followed the other motorbikes through. Nobody seemed to notice or say anything at all. They just waved. We were not stopped once from St. Louis in Senegal all the way to Ghana. And no stopping means no requests for "gifts". It just gets easier and easier. At least for now.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">The trip from the border to Bobo was only a few hours and we arrived at Casa Africa in the city by lunch time. Although the market in the city center is bustling, the majority of Bobo is rather relaxed and sleepy. Bash and I found a decent lunch spot and ate ham and cheese sandwiches - another recent popular lunchtime meal when possible. We spent the afternoon searching unsuccessfully for the yogurt shop, cleaning the bikes, and reading. Bash has every patisserie from Morocco to Cape Town circled in the guide book right now, so the first thing we have to do in every new city we arrive in is to find the sweets shop. Only afterward is there time to find other less essential commodities such as water and food for the road.</p>
                    <p className="description text-left">Our second day in Burkina was a long one. Eager to avoid staying in the capital, Ougadougou, we decided to drive straight from Bobo to the Nazinga Game Park close to the Ghanian border. The road was in good shape, minus the usual potholes and buses that have complete disregard for us on the road, and our progress was rapid. We did have to drive through Ougadougou and were not disappointed we did. The center of the city is bizzare. In attempt to build a grand city center, the government flattened two square kilometers right in the middle of the city. The vision was to build a beautiful cosmopolitan city center, but they ran out of money and it is left barren. I guess the only benefit is that the absence of buildings decreases the overall traffic. Traffic in a large hot African city is not enjoyable on a motorcycle. More on this in Ghana.</p>
                  </Col>
                </Row>
                <Row>
                <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                  <CarouselIndicators
                    items={items2}
                    activeIndex={activeIndex2}
                    onClickHandler={goToIndex2}
                  />
                  {items2.map(item2 => {
                    return (
                      <CarouselItem
                        onExiting={onExiting2}
                        onExited={onExited2}
                        key={item2.src}
                      >
                        <img src={item2.src} alt={item2.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item2.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
                    <p className="description text-left">From Ougadougou we finished the day driving South to the Nazinga Game Park. As we drove South the terrain became greener and greener as well as more humid. Also, the presence of bugs, lizards, and general creepy crawlers increases exponentially with every degree of latitude crossed. More on this in Ghana. We arrived at a game park hotel just before sunset. They kindly offered us free camping in the parking lot if we paid for dinner and a drink. It turned out to be not a very good deal as dinner and drinks cost more than the total price of many of the other hotels we could have stayed at. It was a nice place and the dinner was delicious - a great final night in Burkina.</p>
                    <p className="description text-left">We left early in the morning for Ghana- country number seven.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Mali"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Mali
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Ghana"
                      >
                      Ghana <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default BurkinaFaso;
