import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";


function BlogPosts() {
  return (
    <>
        <div className="projects-4">
          <Container fluid>
            <Row>
              <Col className="px-0" md="6">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/home-page/DSC01581.JPG") + ")"
                  }}
                >
                  <CardBody>
                    <CardTitle className="text-left lead" tag="div">
                      <h2>
                        <Link to="/Congo" >
                          Congo
                        </Link>
                      </h2>
                    </CardTitle>
                    <CardFooter className="text-left">
                      <div className="stats">
                        <span>
                          <i className="now-ui-icons users_circle-08"></i>
                          Justus Meyer
                        </span>
                        <span>
                          <i className="now-ui-icons tech_watch-time"></i>
                          May 12, 2010
                        </span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-0" md="3">
                <Card className="card-fashion">
                  <CardTitle tag="div">
                    <h4>
                      <Link to="/Congo" >
                        Entering the Congo we were wanderers on prehistoric earth, on an earth that wore the aspect of an unknown planet. From our bush camp site the first night...
                      </Link>
                    </h4>
                  </CardTitle>
                  <CardBody>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-0" md="3">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/home-page/DSC01602.JPG") + ")"
                  }}
                ></Card>
              </Col>
            </Row>
            <Row>
              <Col className="px-0" md="3">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/home-page/DSC00695.JPG") + ")"
                  }}
                ></Card>
              </Col>
              <Col className="px-0" md="3">
                <Card className="card-fashion arrow-left">
                  <CardTitle tag="div">
                    <h4>
                      <Link to="/Mali" >
                        "Hey Bash, I think I almost ran over a snake back there on the road." "I saw it, but I was hoping you did not see it and I did not want to tell you about it. It was only about three or four feet long..."
                      </Link>
                    </h4>
                  </CardTitle>
                  <CardBody>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-0" md="6">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/home-page/DSC00646.JPG") + ")"
                  }}
                >
                  <CardBody>
                    <CardTitle className="text-left lead" tag="div">
                      <h2>
                        <Link to="/Mali" >
                        Mali
                        </Link>
                      </h2>
                    </CardTitle>
                    <CardFooter className="text-left">
                      <div className="stats">
                        <span>
                          <i className="now-ui-icons users_circle-08"></i>
                          Justus Meyer
                        </span>
                        <span>
                          <i className="now-ui-icons tech_watch-time"></i>
                          March 29, 2010
                        </span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="px-0" md="6">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/home-page/DSC01175.JPG") + ")"
                  }}
                >
                  <CardBody>
                    <CardTitle className="text-left lead" tag="div">
                      <h2>
                        <Link to="/Nigeria" >
                          Nigeria
                          </Link>
                      </h2>
                    </CardTitle>
                    <CardFooter className="text-left">
                      <div className="stats">
                        <span>
                          <i className="now-ui-icons users_circle-08"></i>
                          Justus Meyer
                        </span>
                        <span>
                          <i className="now-ui-icons tech_watch-time"></i>
                          April 22, 2010
                        </span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-0" md="3">
                <Card className="card-fashion">
                  <CardTitle tag="div">
                    <h4>
                      <Link to="/Nigeria" >
                      Over the past few months, we have spent ample time planning our entry into Nigeria as the section from the border of Benin to Abuja is notorious for insane driving and corrupt police checkpoints...
                      </Link>
                    </h4>
                  </CardTitle>
                  <CardBody>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-0" md="3">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/home-page/DSC01173.JPG") + ")"
                  }}
                ></Card>
              </Col>
            </Row>
          </Container>
        </div>
    </>
  );
}

export default BlogPosts;
