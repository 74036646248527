import React from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { Link } from "react-router-dom";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

  const markers = [
    { markerOffset: -10, name: "Spain", coordinates: [-3.713608, 38.383923] },
    { markerOffset: -10, name: "Morocco", coordinates: [-6.227417, 32.246493] },
    { markerOffset: -10, name: "Mauritania", coordinates: [-14.807396, 18.978705] },
    { markerOffset: 17, name: "Senegal", coordinates: [-15.506058, 14.970329] },
    { markerOffset: -10, name: "Mali", coordinates: [-9.15802, 14.167539] },
    { markerOffset: -10, name: "Burkina Faso", coordinates: [-1.434975, 12.506306] },
    { markerOffset: 20, name: "Ghana", coordinates: [-1.996307, 6.5861515] },
    { markerOffset: 17, name: "Togo", coordinates: [1.060638, 7.808287] },
    { markerOffset: -10, name: "Benin", coordinates: [2.284241, 9.601092] },
    { markerOffset: -10, name: "Nigeria", coordinates: [9.086151, 9.636973] },
    { markerOffset: -10, name: "Cameroon", coordinates: [12.084389, 4.597646] },
    { markerOffset: -10, name: "Gabon", coordinates: [11.085319, -0.835614] },
    { markerOffset: -10, name: "Congo", coordinates: [14.797325, -3.008866] },
    { markerOffset: 17, name: "DRC", coordinates: [17.6626967, -4.670528] },
    { markerOffset: -10, name: "Angola", coordinates: [16.428451, -13.043689] },
    { markerOffset: -10, name: "Namibia", coordinates: [16.570244, -20.982594] },
    { markerOffset: 17, name: "Botswana", coordinates: [23.557548, -21.003109] },
    { markerOffset: -10, name: "Zambia", coordinates: [25.716362, -15.320276] },
    { markerOffset: -10, name: "Zimbabwe", coordinates: [29.700966, -19.290402] },
    { markerOffset: -10, name: "South Africa", coordinates: [20.526695, -32.853054] },
  ];

  const MapChart = () => {
  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        scale: 400,
        style: {
          marginLeft: 0
        }
      }}
    >
    <ZoomableGroup center={[ 15, 0 ]}>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies
            .filter(d => d.properties.REGION_UN === "Africa" || d.properties.SUBREGION === "Southern Europe")
            .map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
              />
            ))
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Link
          to={`/${name}`}
        >
        <Marker key={name} coordinates={coordinates}>
          <circle r={6} fill="#F00" stroke="#fff" strokeWidth={2} />
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: "12px" }}
          >
            {name}
          </text>
        </Marker>
        </Link>
      ))}

      </ZoomableGroup>
    </ComposableMap>
  );
};

export default MapChart;
