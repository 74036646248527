import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Content() {
  return (
    <>
      <div className="section section-content">
        <Container>
          <Row>
            <Col md="7">
              <div className="image-container">
                <img
                  alt="..."
                  className="img rounded img-comments rellax"
                  data-rellax-speed="2"
                  src={require("assets/img/home-page/africa_full_map.jpg")}
                ></img>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="4">
              <div className="section-description">
                <h2 className="title">The Journey</h2>
                <h5 className="description">
                The venture took approximately five months, covered
						close to 17,000 miles, and took us through Morocco, Western
						Sahara, Mauritania, Senegal, Mali, Burkina Faso, Ghana, Togo,
						Benin, Nigeria, Cameroon, Gabon, Congo, Democratic Republic of
						Congo, Angola, Namibia, Botswana, Zambia, Zimbabwe, South
						Africa, and Lesotho.
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Content;
