import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/nigeria/DSC01106.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01107.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01112.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01119.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01124.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01142.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01145.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01147.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01168.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01173.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01175.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01186.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01204.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/DSC01234.JPG"),
    altText: "North South Venture Nigeria"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/nigeria/IMG_4593.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4594.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4597.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4598.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4600.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4601.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4603.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4606.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4608.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4609.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4614.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4615.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4621.JPG"),
    altText: "North South Venture Nigeria"
  },
  {
    src: require("assets/img/countries/nigeria/IMG_4623.JPG"),
    altText: "North South Venture Nigeria"
  }
];

function Nigeria() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/nigeria/DSC01112.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Nigeria</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Benin"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Benin
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Cameroon"
                  >
                  Cameroon <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">April 22nd, 2010</h3>
                    <p className="description text-left">Over the past few months, we have spent ample time planning our entry into Nigeria as the section from the border of Benin to Abuja is notorious for insane driving and corrupt police checkpoints. Aiming to be well situated for our entry, we spent the night in a place just North of Porto Novo called the Songhai center for agricultural research. On the way there, we were careful to stop at the Patisserie in town for a raisin croissant.</p>
                    <p className="description text-left">The next morning we left early for the border. We were surprised when we drove up to a "Welcome to Nigeria" sign without evidence of any passport or immigration facilities. Strange. Were we in the right place? We asked some people where the passport and Duane offices were. Nobody seemed to have any clue where the Duane office was, so we found the passport office and the gentleman stamped us out of the country. He was slightly confused and annoyed when we asked where the Duane office was as it turned out to be located in the town of Ketou, a place we had passed 12km beforehand and he had already stamped us out of the country. Since he did not want to stamp us back into Benin so that we could return to Ketou, he let us go and we returned to Ketou to get our Carnets stamped, although technically we had already exited the country. Always amusing, but a good thing there were no corrupt police officers on that stretch of road as the situation would have been difficult to explain.</p>
                    <p className="description text-left">We drove across the Nigerian border just before noon. Let the fun begin.</p>
                    <p className="description text-left">After 12+ hours of some of the worst driving we have ever experienced, we arrived exhausted at Sara's apartment in Abuja. More to come later.</p>
                    <p className="description text-left">Following the countless horror stories of travel in Nigeria related to us over the past few months, we were psychologically armed to deal with an enormous quantity of hassle at the border. We were disappointed when we were able to drive straight into Nigeria without passing any police or immigration facilities. And the people were very friendly and helpful. Something was off. After asking multiple pedestrians on the side of the street, we learned that the immigration and passport control buildings were one kilometer away. We eventually found the area, much to the surprise of the passport control officer who was asleep. After putting his shirt on, he cheered up and seemed very excited about our journey. He even extended Bash's visa.</p>
                    <p className="description text-left">The Duane officer was equally as pleasant, although he needed much coaching to correctly fill out the necessary forms and wanted my help at every step. Bash giggled in the corner while I pointed to each line on our Carnets and told him what to write, where to sign, and where to stamp. His wife observed equally amused. They were by far the kindest immigration officers we have met thus far. Encouraged by our positive introduction to Nigeria, we pressed on.</p>
                    <p className="description text-left">Almost immediately in Nigeria one notices a comical communication tendency. When one asks for directions, people love to tell you to "just go straight!" One has to be careful with this guidance as "just go straight" can also mean turning right or left. One must watch the person's subtle hand movement to identify which way they truly imply. A slight wave to the left means you will have to turn left eventually, and the opposite for right. Distance to the implied turn is difficult to correctly estimate as distances are generally "optimistic."</p>
                    <p className="description text-left">One also immediately notices the absurd number of checkpoints. The first few that Bash and I encountered were teenagers attempting to abstract a toll, so we drove through without stopping. We stopped at one official police check point and the officers were very friendly and helpful with directions. Ten minutes later I drove through an official checkpoint by accident and the police were furious. They threw a board with nails in it in front of Bash's bike. I immediately returned expecting a tense situation and apologized. The police explained that they were frustrated by how many recent "tourists" were driving through their checkpoints. We quickly became friends and talked about our journey and how nice everyone was in Nigeria. The let us go after twenty minutes and lots of handshakes.</p>
                    <p className="description text-left">As we neared more populated areas, the driving quickly becomes unworldly. Picture huge potholes of unknown depth, utter disregard to any rational driving logic, dense traffic, and lots of speed. One is encouraged to drive all vehicles as fast as one can, until said vehicle either crashes, is damaged from a pothole, or overheats.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">After two hours we arrived in Abeokuta, a relatively small city on the map, but with a population of well over a million people. We asked a kind man on the side of the road to help us with directions and he drew us a very detailed map while sipping a particularly vile looking beverage. This map turned out to be incredibly accurate and useful as the traffic in the city is absurd and there were absolutely no signs.</p>
          					<p className="description text-left">To give you as sense of the aggression of the Nigerian drivers, I will relate a short anecdotal story. As we entered Abeokuta a driver of a midsize van passed us going the other direction. As he passed, he clipped the arm of a woman walking by. Although she shrieked in pain, the van reacted by beeping its horn and continuing on its way. I guess there are few lawsuits here.</p>
          					<p className="description text-left">As it was only 3pm, we decided to head North through Ibadan to Oyo for the night. It did not look very far on the map, but we failed to appreciate that the road was a primary shipping route from Lagos to the North. Thankfully there were so many trucks that most had no choice but to drive very slowly. Our motorcycles were perfect for sneaking around the trucks and using the small moped trails on the side of the road to make progress towards Oyo. We had a tough time trying to navigate through Ibadan, but were helped by a nice Toyota truck with a license plate that said Presidency who gave a long series of directions and finished by saying, "and when you hit the intersection, just go ssssttttrrrraaaaiiigggghhhttt!!!!" I burst out laughing and had to close my visor to not seem rude.</p>
          					<p className="description text-left">The shape of the roads is also entertaining in Southwest Nigeria. As it is so hot and large trucks continually use the road, the pavements heats so much throughout the day that it melts and the trucks create tracks in the pavement. Riding along in these tracks gives one the sensation of bobsledding.</p>
          					<p className="description text-left">We reached the La Bamba hotel in Oyo as the sun set for the night. Unfortunately we had only exchanged a tiny bit of money at the gas station during the day and did not have enough left to pay for a room. The hotel staff kindly let us camp on their lawn for $10 USD each, expensive for camping, but we welcomed the break from the day's driving. We only had the equivalent of $7 USD in Nigerian dollars left for dinner and we bargained with the hotel restaurant cooks to make us as much food as possible for what we had. The meal consisted of an omelet and bread.</p>
          					<p className="description text-left">I do not think the hotel staff had ever seen a tent before and were very intrigued by them. They all came over to look them over, ask how they worked, where we had come from, and what we would do if it rained. We were fast asleep by 10 pm.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                  <h3 className="title text-left">April 23rd, 2010</h3>
                    <p className="description text-left">We knew that the next day was going to be long, as it was almost 450 miles to Abuja from Oyo and we did not know how good the roads were. We quickly found a new freeway which was almost complete and our progress was rapid.</p>
          					<p className="description text-left">Around midday we had covered almost half of the distance to Abuja and exchanged enough money that we could get gas and eat a few snacks. Then the road deteriorated into a series of enormous potholes slowing our pace to between twenty and thirty miles an hour. Every few hundred feet we would be have to slam on the breaks to avoid careening into a large pothole. On this road it seemed that everyone weaved around the potholes, even the huge trucks. It is intimidating to see a large oil truck coming at you at forty miles an hour on your side of the road before it swerved aggressively into the appropriate lane before an imminent collision. Bash and I were both driven off the road quite a few times over the next few hours.</p>
          					<p className="description text-left">Around 4 pm the road started to improve and two large motorcycles approached us in our rear view mirrors - Ian from the UK and Mick from Denmark. We chatted for a few minutes and then traveled on. Around 5:30 I turned a corner and the sky looked black up ahead. The best way I can explain it to you is by saying that, for a second or two, I felt as though, instead of going to the center of the continent, I were about to set off for the center of the earth. Within twenty minutes all four motorcycles were forced to find shelter under a small grove of trees to wait out a powerful thunder storm.</p>
          					<p className="description text-left">By 6pm the storm subsided and we navigated to Sara's house in Abuja. Paradise found again. We were a little worried that our drowned and dirty appearance may cause her to retract her offer of a place to stay, but we assured her that after a shower we would look somewhat normal. Actually, our appearance (mostly mine) had reached a tragic state. After the thunderstorm we had to ride with our visors up to see the road and as a result our faces were coated with dirt. Bash kept on joking that it looked like I was wearing eye shadow. The staff members at the Piano Lounge in the Hilton Hotel were particularly horrified by my appearance when I stopped by to ask for directions as most people were wearing suits. They watched me closely to ensure that I did not steal anything. As it seemed that almost everyone I met was sufficiently scared of my appearance, I finally shaved the moustache the next day. I think Sara's roommates are much relieved and have stopped locking their doors.</p>
          					<p className="description text-left">After battling an episode of food poisoning at the beginning of the week, Bash and I successfully received our Angola, Congo, and DRC visas. The plan is to leave Saturday morning for the center of the earth. Wish us luck.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">April 25th, 2010</h3>
                    <p className="description text-left">Leaving Abuja was difficult. We had been there for eight days, consumed 43 cappuccinos, and 58 pain au chocolats. We were clean, shaven, and tan - in other words, we were losing our riding "toughness." The first few hours driving were typical of our time on the road in Nigeria. More accurately, we spent this time waiting for early Saturday morning "sanitation" to end in Abuja. I had not heard of this before, but the policeman on the way back from breakfast at the Hilton was adamant that I not be outside during "sanitation" hours. Driving was out of the question so we waited until 10 am when it was finished.</p>
          					<p className="description text-left">The drive from Abuja to Makurdi where we spent the night was easy. The road was fantastic and mostly free of potholes. We reached the Dolphin Hotel around 3pm and was very glad we did as a fly had flown into my coat and was starting to attack me. It buzzed fiendishly, and did not sting, but stabbed. We spent the remainder of the afternoon reading and drinking sodas. I was deeply engrossed in the first book of the Twilight series.</p>
          					<p className="description text-left">At 6pm I brought our stove out to the parking lot and prepared to cook a pasta dinner. The hotel manager thought this was very bizarre and told me that they had a restaurant. Fantastic! We found the room and perused the menu. I will have the chicken and fried rice. No chicken and fried rice? Well, let's see. I will have the beef and white rice. No beef and white rice? What about this word I cannot pronounce? Not that either? Well, what do you have? Chicken spice soup - make it two. As the name implies, the chicken spice soup was rather spicy. Or completely inedible. It also looked like it had been cooked about two weeks beforehand. After poking the chicken for 15 minutes trying to find a bag to put the chunks in so that I would not seem rude for not eating it, I gave up. Not wanting to go to bed on an empty stomach, Bash went to the room to get our pasta and hot water pot. We asked if they may be able to cook the pasta in their kitchen. Why of course - but why don't you do it yourself. With a pot on a burner boiling away I poured in the pasta and cooked away in the hotel kitchen. Five minutes later we finally ate.</p>
          					<p className="description text-left">Cleaning up dinner was less successful. First, I went to throw away the pasta packaging only to realize that I had tossed the plastic wrapping into their water bucket. I quickly removed the rubbish before anyone saw me and searched for a trash. None to be found. Morally still unable to throw my trash on the ground, I put it in my pocket to be disposed of later. I won't get into what happens to most of the trash in Africa, but one can probably guess. Next I went to pour out the extra pasta into the sink and wash the pot. Hum, no water. What to do now? I scooped the pasta remnants from the sink and put them into my damp plastic packaging, grabbed our cooking ware, and quickly left the kitchen before I could cause more damage.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Benin"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Benin
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Cameroon"
                      >
                      Cameroon <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Nigeria;
