import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/togo/DSC01020.JPG"),
    altText: "North South Venture Togo"
  },
  {
    src: require("assets/img/countries/togo/DSC01024.JPG"),
    altText: "North South Venture Togo"
  },
  {
    src: require("assets/img/countries/togo/DSC01027.JPG"),
    altText: "North South Venture Togo"
  },
  {
    src: require("assets/img/countries/togo/DSC01028.JPG"),
    altText: "North South Venture Togo"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/togo/DSC01029.JPG"),
    altText: "North South Venture Togo"
  },
  {
    src: require("assets/img/countries/togo/DSC01039.JPG"),
    altText: "North South Venture Togo"
  },
  {
    src: require("assets/img/countries/togo/DSC01046.JPG"),
    altText: "North South Venture Togo"
  },
  {
    src: require("assets/img/countries/togo/DSC01053.JPG"),
    altText: "North South Venture Togo"
  }
];

function Togo() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/togo/DSC01020.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Togo</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Ghana"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Ghana
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Benin"
                  >
                  Benin <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">April 15th, 2010</h3>
                    <p className="description text-left">Our exit from Ghana could have gone more smoothly. After leaving Accra and Jenny's sanctuary, Bash and I drove North to Wli falls for the night. We found a picturesque place to camp on the lawn of the Wli Falls Lodge with stunning views of the mountains and waterfall. We were thinking about hiking up to the falls, but as we could see them from our tent and I spotted some badminton racquets, Bash and I spent the remainder of the evening battling it out in the West African Badminton Championships. It was unclear who the victor was, but Bash won the sportsmanship award as I continually hit the ball into the jungle and Bash was kind enough to retrieve it from the land of the beasties without many complaints.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">Our plan the next morning was to cross the quiet border 2km away and then head South to Lome for the night. Unfortunately, the police on the Ghana side told us that the border on the Togo side did not issue visas and we should drive 150km South to Alfao, near Lome, and cross there. We tried to argue that we did not need a visa to enter Togo, but they correctly refuted this claim. To confirm our conversation, the Ghana police allowed me to walk the half kilometer to the Togo side to speak with police there. Same story. Unperturbed we headed South for a second attempt.</p>
          					<p className="description text-left">Three hours later we reached the new border, although this time the border was chaotic, swarming with all flavors of seedy characters shouting and attempting to mislead us, some complete with police uniform. After spending some time finding a police officer we thought may have some idea of how to help us find the various necessary stations, Bash and I took turns watching the bikes while the other worked on getting our passports and Carnets stamped.</p>
          					<p className="description text-left">The most hilarious moment of the day was when one police officer asked to take a photo of me for their system. I smiled broadly and he snapped the photos. Five minutes later he asked to take a photo of "Mr. Justus." I explained that I was "Mr. Justus." Confused, he realized that he had taken a photo of me under Bash's name. I told him he could take a photo of Bash under my name as we both looks similar and it was not like anyone would ever know anyway, but he said that would not due as his boss was in the room. He eventually insisted on taking another photo of me so that he would not get into trouble, this time I did not smile to make the photos look slightly different. He seemed pleased with the solution, never mind that there are now two people on Ghana's immigration system that have the exact same face. Still giggling from the experience, we drove into Lome just after 2 pm.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
          					<p className="description text-left">We have recently been extremely worried about the tragic state of our rear sprockets and heard that there was a good mechanic named Tony in Lome that may be able to help us. We also were eager to finally change the rear tire as we had been carrying a spare and the original tire was starting to wear thin. Tony was able to change the tires for us for $4 each, but had no spare parts for BMWs. New sprockets will have to wait and hopefully our current set will last us to Namibia where we can easily get them shipped up from South Africa. I am optimistic that we will make it. It is possible that this optimism is derived from my fear of further customs clearing challenges if we decided to have these parts shipped to Cameroon or Gabon.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Ghana"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Ghana
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Benin"
                      >
                      Benin <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Togo;
