import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/southafrica/DSC01215.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01218.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01234.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01241.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01246.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01254.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01260.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01275.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01282.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01292.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01293.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01309.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01312.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01320.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01324.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01326.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01336.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01338.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01341.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01355.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01363.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01369.JPG"),
    altText: "North South Venture South Africa"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/southafrica/DSC01372.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01373.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01384.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01412.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01423.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01426.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01437.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01440.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01445.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01453.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01454.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/DSC01463.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5440.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5445.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5479.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5541.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5550.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5555.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5564.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5568.JPG"),
    altText: "North South Venture South Africa"
  },
  {
    src: require("assets/img/countries/southafrica/IMG_5577.JPG"),
    altText: "North South Venture South Africa"
  }
];

function SouthAfrica() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/southafrica/DSC01409.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">SouthAfrica</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Zimbabwe"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Zimbabwe
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Spain"
                  >
                  Spain <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">June 28th, 2010</h3>
                    <p className="description text-left">On 22 June we reached Cape Agulhas, the Southernmost tip of Africa and the official finish line of our trip!</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">You may notice that we do not have a full blog for South Africa and our journey into Lesotho.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
                    <p className="description text-left">After months and months of traveling and blogging - we are finally out of content.  Just in time. </p>
                    <p className="description text-left">Special thank you to everyone that followed this trip and everyone that helped us along the way! Since our moms read this blog, we had to hold back on telling some of the scarier events.. We are saving those for dinner time stories when we are old :)</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Zimbabwe"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Zimbabwe
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Spain"
                      >
                      Spain <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default SouthAfrica;
