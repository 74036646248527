import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/benin/DSC01054.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01055.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01059.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01063.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01064.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01074.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01080.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01083.JPG"),
    altText: "North South Venture Benin"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/benin/DSC01089.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01091.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01096.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01097.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01099.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01101.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/DSC01102.JPG"),
    altText: "North South Venture Benin"
  },
  {
    src: require("assets/img/countries/benin/IMG_4586.JPG"),
    altText: "North South Venture Benin"
  }
];

function Benin() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/benin/DSC01080.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Benin</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Togo"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Togo
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Nigeria"
                  >
                  Nigeria <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">April 16th, 2010</h3>
                    <p className="description text-left">Togo is a very small country and the next morning it took us two hours to drive all the way to the Benin border where the crossing was relaxed and straight forward. We arrived at the Auberge Grand Popo ten kilometers from the border by early afternoon.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">I comment to Bash almost every day that the farther we travel, the better the room and board gets. At the Auberge Grand Popo we were able to camp right next to the beach and use all of the hotel's facilities including the pool for free. I highly recommend the mushroom pizza and fruit crepes. The Auberge is located next to a sleepy fishing village and I ventured down the beach to look for people unloading their catches and to help pull in the nets for the day. My timing was not impeccable and all of the fishermen were sleeping in the shade.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
          					<p className="description text-left">As the next day's drive was short, we decided that it would be an opportune time to get some extra pages added to our passports in Cotonou. We found the US Embassy without much trouble and the mission was accomplished in a matter of hours. Throughout the day we noticed that the driving was getting more and more aggressive. A taste for what Nigeria would be like.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Togo"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Togo
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Nigeria"
                      >
                      Nigeria <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Benin;
