import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container
} from "reactstrap";

function DropdownWhiteNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img id="logo" alt="North South Venture Logo" src="https://firebasestorage.googleapis.com/v0/b/tetate-fcc09.appspot.com/o/NSV_large_logo_dark.png?alt=media&token=a65fb43e-2567-4cca-b2a0-bc0662bb061b"/>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <NavLink tag={Link} to="/about-us">
                  About
                </NavLink>
              </NavItem>
              <NavItem nav>
                <NavLink tag={Link} to="/vision">
                  Vision
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/tips">
                  Tips
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <p>North</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem tag={Link} to="/Spain">
                    Spain
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Morocco">
                    Morocco
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Mauritania">
                    Mauritania
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Senegal">
                    Senegal
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Mali">
                    Mali
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Burkina Faso">
                    Burkina Faso
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Ghana">
                    Ghana
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Togo">
                    Togo
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Benin">
                    Benin
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Nigeria">
                    Nigeria
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <p>South</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem tag={Link} to="/Cameroon">
                    Cameroon
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Gabon">
                    Gabon
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Congo">
                    Congo
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/DRC">
                    DRC
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Angola">
                    Angola
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Namibia">
                    Namibia
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Botswana">
                    Botswana
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Zambia">
                    Zambia
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/Zimbabwe">
                    Zimbabwe
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/South Africa">
                  South Africa
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                <NavItem className="active">
                  <NavLink tag={Link} to="/journey">
                    <p>Start Journey</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default DropdownWhiteNavbar;
