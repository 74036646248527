import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/gabon/DSC01428.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01433.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01436.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01440.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01445.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01446.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01447.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01450.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01452.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01454.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01456.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01458.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01466.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01470.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01471.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01487.JPG"),
    altText: "North South Venture Gabon"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/gabon/DSC01490.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01493.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01499.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01505.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01516.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01518.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01521.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01527.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01531.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01536.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/DSC01541.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/IMG_4760.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/IMG_4789.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/IMG_4798.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/IMG_4810.JPG"),
    altText: "North South Venture Gabon"
  },
  {
    src: require("assets/img/countries/gabon/IMG_4822.JPG"),
    altText: "North South Venture Gabon"
  }
];

function Gabon() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/gabon/DSC01527.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Gabon</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Cameroon"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Cameroon
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Congo"
                  >
                  Congo <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">May 9th, 2010</h3>
                    <p className="description text-left">The road to the Gabon border was perfect again and we exited Cameroon by 1 o'clock.</p>
                    <p className="description text-left">I can't find much WIFI, but will update the blog when we get a chance. We have all of the visas we need to get to Namibia and are headed South in the morning. Wish us luck - we should be in Namibia in two weeks.</p>
          					<p className="description text-left">One of my least favorite experiences traveling is when I enter a city, find a hotel, and hear the familiar cry, "I am sorry, we are all full for the night." It is even less pleasurable in French as it takes me more time to understand the situation. Such was our experience entering Oyem for our first night in Gabon. Usually such cries are repeated around town until you realize that it is a special holiday you had never heard of before and you may be sleeping on the street.</p>
          					<p className="description text-left">Immediately realizing our predicament, when Bash and I reached the Mvet Hotel in Oyem, we were not going to take no for an answer. Sitting in the lobby after being told "we do not have any rooms," multiple times may seem like an inefficient use of time, but we did not have a good alternative. Eventually the hotel manager took pity on us and found us a nice concrete tunnel to pitch our tents by the hotel's lawn. Complete with a view of the "pond" and a rather spectacular sunset. We immediately unpacked our equipment to dry out from the previous night's rain.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">May 11th, 2010</h3>
                    <p className="description text-left">The next morning we set off early for Lope national park. We soon passed our three Spanish friends who were broken down on the side of the road. They had driven their large Peugeot van all of the way to Gabon, but not without some major repairs along the way. The problem this time: a broken front shock. Bash tried to offer some mechanical advice, but unfortunately this repair was a bit out of our league with the equipment available to us. We said our goodbyes, wished them luck, and continued on.</p>
                    <p className="description text-left">The last 90km of the road to Lope becomes very rough our progress slowed to a crawl. Near sunset, still 30km away from our destination, we found a beautiful bush camp site off the side of the road in the mountains and enjoyed a perfectly cooked ramen dinner with scenery to match. After watching a lighting storm above a mighty river in the distance we settled into our tents for the night.</p>
                    <p className="description text-left">As I lay in my tent listening to the slight rain at 5:45 am I heard a slight buzzing outside my tent. I didn't think much of it at the time, but soon the buzzing turned into two separate distinct buzzes, followed then by a buzzing chorus. Bees! Swarms of them. We packed as quickly as we could in an attempt to keep the interaction from becoming unpleasant. I snickered to myself as I watched Bash try to coerce a bee out of his tent as he was trying to dismantle the structure. The ten bees circling Bash's body were also laughing.</p>
        					  <p className="description text-left">Unfortunately the rain that night turned the difficult dirt road into a vile muddy survival track. Progress became slower and slower as we puttered along trying to avoid falling or getting out of control going down hills. Then, abruptly, road disappeared into a flooded murky swamp. We could barely see the road again on the other side, but it looked as if a crossing was neither possible nor wise. We both stared at the expanse of water trying to convince ourselves that it was not as deep as it looked. A passerby told us that it was only shin deep, but it certainly did not look that way. Not keen to spend ample time walking through the dirty water filled with unimaginable beasties and certainly snakes, I told Bash I was going for it. The first 50 meters were easy enough, and then my bike began to submerge a little bit more. Then a little bit more. I held my breath. Soon very little of my bike was still poking out of the water. Knowing full well that one of the worst things I could do to the bike was have water enter the engine air inflow, I could not afford the water to get any deeper. I crept along at a snail's pace to prevent the water from covering the snorkel area of the bike. 10 meters, 20 meters, 30 meters, 40 meters. Then, miraculously, I was going uphill and the bike was gaining altitude out of the water. Soon I was on the other side shaking from my bike's close encounter with death. I hoped this was the last swamp crossing of the day.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
          					<p className="description text-left">After a few more hours in the mud the rain subsided and the road improved. By 2pm we had made it almost halfway to Franceville, near the border of the Congo. We had been told that we would be able to get gas in a small town nearby, but the gas station was all out. Having already driven more than 300km through tough terrain since last filling up with gas we were reluctant to embark upon another 100km journey to the next gas station. Plus, why would they have gas at the next town if there was none here? After all, we were traveling further away from Libreville. Seeing no other option, we pressed on, careful to keep the RPMs as low as possible. After ten miles the gas light came on signaling that we only had one gallon of gas left in the tank. We spent the next two hours willing our bike to go just a little bit further. With luck on our side that day, we reached a gas station on the last remnants of the tank, and reached Franceville an hour later just before dark.</p>
                    <p className="description text-left">We found what seemed to be good hotel in Franceville and quickly fell asleep in our cool air conditioned room. As is frequent in this part of Africa, a power outage soon knocked out our air conditioning and our room turned from a restful haven into a mosquito dinning hall. Bash quickly assembled his tent and relaxed on the floor. As there was no more room on the floor I assembled on the tent and placed it back on the bed. Comfortable, although I am not sure the maid was so pleased in the morning as the bottom of my tent has accumulated some dirt over the course of our travels.</p>
        					  <p className="description text-left">Exhausted from a long two days of driving and little sleep from the night before, we spent the next morning finding food, water, one last cappuccino, money, and lots of extra gas. We were not sure whether we were going to be able to get any gas before Brazzaville and we did not want to risk running out on the way. By 1 o'clock in the afternoon we were back on the road headed toward the Heart of Darkness.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Cameroon"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Cameroon
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Congo"
                      >
                      Congo <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Gabon;
