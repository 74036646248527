import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/mali/DSC00622.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00625.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00626.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00635.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00646.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00648.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00649.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00651.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00655.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00658.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00663.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00671.JPG"),
    altText: "North South Venture Mali"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/mali/DSC00685.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00695.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00699.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00701.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00709.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00711.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00713.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00722.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/DSC00725.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/IMG_4225.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/IMG_4234.JPG"),
    altText: "North South Venture Mali"
  },
  {
    src: require("assets/img/countries/mali/IMG_4306.JPG"),
    altText: "North South Venture Mali"
  }
];

function Mali() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/mali/DSC00725.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Mali</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Senegal"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Senegal
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Burkina Faso"
                  >
                  Burkina Faso <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">March 29th, 2010</h3>
                    <p className="description text-left">
                    "Hey Bash, I think I almost ran over a snake back there on the road." "I saw it, but I was hoping you didn't see it and I didn't want to tell you about it. It was only about three or four feet long." I really wish I had not seen it. I really hate snakes, even little ones. Three or four feet long! It didn't help that it was our first afternoon in Mali and we were trying to find a good spot to bush camp for the night. By this point every little stick looked like a snake to me. I made very sure that all of the zippers on my tent were closed tightly while I cooked dinner. Bash did not seem very disturbed by the event.
                    </p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                  	<p className="description text-left">Following the border crossing into Mali, we rode through the town of Kayes, past Diema, and camped a few hundred meters off the road. It was a beautiful spot and we ate a delicious pot of spaghetti and mixed vegetables for dinner, canned mango for desert, and sugar biscuits for second desert. We have both developed a bit of a sweet tooth and it is important to have multiple deserts after dinner. We don't feel guilty about it as many days on the road come without any true meals at all and it is imperative that we stock up on calories at every opportunity. So we tell ourselves.</p>
        						<p className="description text-left">The next morning we woke up a little after six to ride the rest of the way to Bamako. We find the early morning roads to be very pleasant in this part of Africa as the middle of the day is quite hot. Really hot in fact. Crowded and hot.</p>
        						<p className="description text-left">Closer to Bamako we drove past a livestock sale. I apologize to anyone who reads this who may care about animal treatment as I am not sure how well this sale would be received in the United States. It would be shut down immediately by any animal rights organization. While the sale seemed normal enough, it was immediately apparent that nobody has the means by which to transport their live purchases, so they just tie the animals anyway they can to their car. The most popular method seemed to be on the roof, but the back seat was appropriate, or to the side back window.</p>
        						<p className="description text-left">Later on in the drive we ran into some children on the side of the road who were very excited to see us. We decided to stop and say hi. Communication was somewhat limited as our French is very limited, but we had fun trying to speak. Bash gave the kids some colored pencils and empty Fanta bottles. Everyone in Mali is really friendly and you spend most of your day driving waving and shouting "Bonjour, ca va?" There is also a lot of handshaking and smiling. You shake hands when you meet and when you say goodbye. It is very friendly.</p>
                  </Col>
                </Row>
                <Row>
                <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                  <CarouselIndicators
                    items={items2}
                    activeIndex={activeIndex2}
                    onClickHandler={goToIndex2}
                  />
                  {items2.map(item2 => {
                    return (
                      <CarouselItem
                        onExiting={onExiting2}
                        onExited={onExited2}
                        key={item2.src}
                      >
                        <img src={item2.src} alt={item2.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item2.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
                    <p className="description text-left">We reached Bamako around two o'clock in the afternoon and went to the Auberge D'Tamilla run by a swiss couple. It was very comfortable and offered great WIFI, sodas, a room with a fan, and other overlanders to chat with. Bash went off downtown to look for a Patisserie while I attended to some emails. It was a relaxing afternoon and a good place to rest for the next section of our venture into Ghana. The eight sodas we consumed at the Auberge aided our relaxation although the barman was perplexed as to how we had consumed so much and it was slightly embarrassing to pay the bar tab.</p>
          					<p className="description text-left">Today we rode a shot distance to a place just before Segou. Just when we thought we were hot stuff, we passed a French couple on a tandem bicycle. Seeing them made us feel like we were wimps on our motorcycles. They are spending over a year riding to Cape Town and then another year riding back up to Egypt. Very impressive. We agreed that we were not tough enough. We said goodbye, wished them luck, and rode on.</p>
          					<p className="description text-left">Mali has been wonderful and we will be sad to leave in the morning. The next stop: Burkina Faso.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">April 6th, 2010</h3>
                    <p className="description text-left">
                    Our final night in Mali was blissful. We spent it at the Agricultural Research Center guesthouse about 40km south of Segou. It is located 4km down a dirt road past two beautiful and friendly small villages. We dinned on the now frequent meal of french fries mixed with mystery meat. The lady and family in charge of the guesthouse could not have been nicer.
                    </p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Senegal"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Senegal
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Burkina Faso"
                      >
                      Burkina Faso <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Mali;
