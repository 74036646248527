import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import VisionHeader from "components/Headers/VisionHeader.js";
import Footer from "components/Footers/Footer.js";

function Vision() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <VisionHeader />
        <div className="section">
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h2 className="title">Objective 1</h2>
                    <h4 className="description text-justify">
                    To motorcycle down Western Africa from the northern tip of Morocco to the southern tip of South Africa. We started in Madrid, Spain and travel south to catch a ferry to Africa. The venture will take approximately five months, will cover close to 10,000 miles, and takes us through Morocco, Western Sahara, Mauritania, Senegal, Gambia, Mali, Burkina Faso, Ghana, Togo, Benin, Nigeria, Cameroon, Gabon, Congo, Democratic Republic of Congo, Angola, Namibia, Botswana, and South Africa.As the security in a few of the listed countries is worrisome, this route may change subtly and may include air transport around specific countries we feel pose serious risk. While we want to complete the entirety of the venture on land, we feel the impurity of skipping one country is worth the extra margin of safety. While we want to complete the entirety of the venture on land, we feel the extra margin of safety is worth the impurity of skipping one country. It will also help us sleep better at night. We will update our progress and route alterations on the blog section of this website.
                    </h4>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h2 className="title">Objective 2</h2>
                    <h4 className="description text-justify">
                    To raise $5,000 for the Millennium Villages Project (MVP), a partnership developed by The Earth Institute at Columbia University, Millennium Promise, and the United Nations Development Programme (UNDP). More specifically, we will be raising money for Millennium Promise, which is the primary financial contributor to the Millennium Villages Project. The principle reason we have embarked upon this venture is to better understand West Africa and the challenges that the inhabitants face. We hope that this trip is merely the beginning of a long-term effort to contribute significantly to many of the areas we visit. We have identified the MVP as an effective organization that has initiated powerful positive changes in these areas as well as Africa as a whole. Any help we can give to the Millennium Villages will not only benefit the lives of the families within the communities, but also their region, their countries, and frankly the world itself. Please join us in supporting this initiative &#45; let&#39;s do something great.Just click on the &quot;Give to Millennium Villages Project&quot; at the top of this page to make a contribution.The Millennium Villages Project aims to demonstrate that even the poorest and most remote communities in rural sub-Saharan Africa can implement and manage the range of interventions required to achieve the Millennium Development Goals (MDGs). MVP partners with communities to create and facilitate sustainable, community-led action plans that are context-specific and tailored to meet communities&#39; priorities. The idea is to show that simple solutions such as the use of high-yield seeds, fertilizers, and anti-malarial bed nets combined with infrastructure improvements of key public facilities and capacity building at the local level are effective at combating extreme poverty and nourishing communities into a new age of health and opportunity.
                    </h4>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Vision;
