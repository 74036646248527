import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// core components

function Components() {
  return (
    <>
      <div
        className="section section-components homepage-one"
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center title">
                Welcome to North South Venture <br></br>
              </h2>
              <h5 className="text-center description">
              A motorcycle adventure from Madrid to Cape
    Town down the West Coast of Africa by Justus Meyer and Sebastian
    Reeve.
              </h5>
              <div className="space-50"></div>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <div className="card-container first-card">
                <div className="card-component">
                  <div className="front">
                    <Link to="/Mali" >
                      <img
                        alt="North South Venture Mali"
                        src={require("assets/img/home-page/mali_screen.jpg")}
                      ></img>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container second-card">
                <div className="card-component">
                  <div className="front">
                    <Link to="/Gabon" >
                      <img
                        alt="North South Venture Gabon"
                        src={require("assets/img/home-page/gabon_screen.jpg")}
                      ></img>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container third-card">
                <div className="card-component">
                  <div className="front">
                    <Link to="/Congo" >
                      <img
                        alt="North South Venture Congo"
                        src={require("assets/img/home-page/congo_screen.jpg")}
                      ></img>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container fourth-card">
                <div className="card-component">
                  <div className="front">
                    <Link to="/Angola" >
                      <img
                        alt="North South Venture Angola"
                        src={require("assets/img/home-page/angola_screen.jpg")}
                      ></img>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Components;
