import React from "react";
// react plugin used to create DropdownMenu for selecting items
import { Alert } from 'reactstrap';

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import Footer from "components/Footers/Footer.js";
import MapChart from "./MapChart";

function Journey() {
  const [alert1, setAlert1] = React.useState(true);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <DropdownWhiteNavbar />
      <div className="wrapper">
        <div className="section">
          <div className="about-description text-center">
            <div className="features-3">
            <Alert color="info" isOpen={alert1}>
              <Container>
                <div className="alert-icon">
                  <i className="now-ui-icons location_world"></i>
                </div>
                <strong>Click</strong> any country to begin
                <button
                  type="button"
                  className="close"
                  onClick={() => setAlert1(false)}
                >
                  <span aria-hidden="true">
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </span>
                </button>
              </Container>
            </Alert>
            </div>
          </div>
          <div className="about-description text-center">
            <div>
              <MapChart/>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Journey;
