import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/morocco/DSC00136.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00142.jpg"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00153.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00172.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00210.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00211.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00230.jpg"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00239.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00274.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00284.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00295.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00304.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00317.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00324.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00350.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00356.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00358.jpg"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00364.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00365.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00374.JPG"),
    altText: "North South Venture Morocco"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/morocco/DSC00393.jpg"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00403.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00412.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00413.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00417.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00439.jpg"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00447.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00451.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00481.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00516.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/DSC00527.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3736.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3760.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3849.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3898.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3907.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3916.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3923.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_3990.JPG"),
    altText: "North South Venture Morocco"
  },
  {
    src: require("assets/img/countries/morocco/IMG_4060.JPG"),
    altText: "North South Venture Morocco"
  }
];

function Morocco() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/morocco/IMG_3849.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Morocco</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Spain"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Spain
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Mauritania"
                  >
                  Mauritania <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">February 28th, 2010</h3>
                    <p className="description text-left">
                    It has been an exciting few days, but we have made it to Fez in Morocco. We will post more about our experiences the last few days later. This keyboard is not exactly user friendly.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">March 2nd, 2010</h3>
                    <p className="description text-left">
                    Morocco has been incredible!! A quick summary of the past few days:
                    </p>
                    <p className="description text-left">
                    The trip from Tarifa to Tangier was relatively uneventful. That is except for the wind, and a mighty wind it was. Keeping the bike upright while parked was a challenge. Riding was a different story. But more on this later.
                    </p>
                    <p className="description text-left">
                    On the ferry, Bash and I met a few Spanish motorcyclists who were very helpful giving us advice for what to see and where to stay in Morocco. We also tried our hand at motorcycle shop talk. I need some work in this department.
                    </p>
                    <p className="description text-left">Customs in Tangier was strangely easy. Nobody seemed very concerned with us. Nobody even mentioned the US license plates. It took about an hour in total. The hardest part was trying to find someone to stamp our papers after we parked our bikes outside the customs area.</p>
          					<p className="description text-left">Pleased with ourselves, we immediately left the port area of Tangier convinced that we should leave before our luck changed. Seeking a quieter route than the main highway south, we began our journey on back roads to Fes. The weather quickly deteriorated. The wind worsened. We soon entered a labyrinth of flooded back roads. The flooding became so bad, that we had to drive almost 3 hours out of our way to return to the main highway south. By this time the driving was comical - 40+ mph winds with unmentionable gusts. We coined a new phrase EIOFA. Every inch of Africa. No easy days, not even in Morocco.</p>
          					<p className="description text-left">We found a small hotel in Kinetra and slept very well. The next day we drove the rest of the way to Fes. We spent the day drying our still wet gear and exploring the intricate and dizzying inner city.</p>
          					<p className="description text-left">Yesterday and today have made the trip thus far. The drive to Fes to Erg Chebbi in the desert was fantastic. Beautiful endless plains, lush oases, and finally the huge sand dunes of the Sahara. Bash and I climbed an especially tall one to get some exercise. We made powder eight turns back down - Jeff, Matt, Patrick, and Aaron would be proud.</p>
          					<p className="description text-left">Although the weather has been fantastic in the desert, it has continued to rain inland. The result: massive flooding. Huge rivers have descended into the arid sandy desert towns. Bash and I practiced our river crossing skills today. A+ performance. We opted out of one where we saw a car submerged up to the mid-windshield. Fortunately that was not a road we needed to take - unfortunately that road led to the beautiful Todra gorge. Next time.</p>
          					<p className="description text-left">Currently we are camping in a Kasbah outside of Dades. Another beautiful town after the most beautiful sunset we have seen in a long trip. The last 30 minutes driving today are best described using a version of the Conard quote I tried to sneak into a quarterly letter: The flood had made, the wind was nearly calm, and being bound down the highway, the only thing for it was to come to and wait for the dazzling sunset.</p>
          					<p className="description text-left">Tonight, we celebrated Bash's birthday with one beer - we would have had a bottle of wine, but we were too tired - what a birthday it was! </p>
          					<p className="description text-left">Will update all of the photos when i get better internet.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">March 4th, 2010</h3>
                  	<p className="description text-left">The weather has been fantastic, the roads have been perfect, the scenery spectacular, the people friendly, and the food divine. Life could not be better in Morocco.</p>
        						<p className="description text-left">From Dades gorge, where we were again foiled by heavy flooding, we headed West to the exotic Ait Benhaddou Kasbah. Apparently parts of Lawrence of Arabia were filmed here and an appropriate visit after our time in the desert. It did not disappoint. Bash and I were even required to ride donkeys to see the fortress. Please see attached photo of Bash (I am glad he did not have the foresight to take a photo of me.</p>
        						<p className="description text-left">While we camped two nights ago near Dades, the higher parts of Morocco are far colder than we expected, even below freezing at night. As the majority of our gear is made for the warmer parts of Africa, we will opt for hotel rooms until the temperatures increase. Needless to say, our 45 degree sleeping bags did not cut it that night.</p>
        						<p className="description text-left">Today we drove from Ait Benhaddou to Oukaimeden ski resort. Yes, there is skiing in Morocco. Worried we would miss out on the last skiing of the season in the US as we left before the end of the winter, we felt obligated to have one last day. I may change my mind tomorrow when we see the rental gear available. Also, I am not sure if insurance is available and we all know what I do to skis..</p>
        						<p className="description text-left">The drive here was blissful - almost 220km of windy perfectly tarred roads. The last 50kms were very steep and eventually disappeared into a dense cloud. We broke through the cloud layer 200m below Oukaimeden in time for a beautiful sunset on the ski resort.</p>
        						<p className="description text-left">I expect the next post will include some exceptional skiing photos.</p>
        						<p className="description text-left">Please note: there is has been a steady degradation in our appearances, particularly mine. If anyone is worried, this look is ephemeral and will be abandoned upon completion of the trip.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">March 7th, 2010</h3>
                  	<p className="description text-left">Skiing in Oukaimedene was an experience! The conditions were challenging - mostly frozen death cookies, but the terrain was very entertaining. As were the other skiers, the rental equipment, the parking attendants, and the general audience. I managed to find a pair of old twin tips so we could do at least a few simple tricks. I will post all of the photos next time we get Wifi.</p>
          					<p className="description text-left">From the mountains we ventured into Marrakesh to rest for a few days. Happy to finally have a day free from the struggles of riding, we read, shopped, socialized, and drank many many cafe au laits. Marrakesh is a wonderfully lively place - offering dancing cobras and boxing to story tellers relating long and elaborate tales.</p>
          					<p className="description text-left">My first exposure to snakes on this trip was not positive. I can deal with spiders and all other beasts, but I truly despise snakes. Many of the young salesmen of the city have wooden snakes for sale which look very real. When one touched my jacket I jumped away and shouted in distress much to the delight of the all observers. This is not a good sign for things to come.</p>
          					<p className="description text-left">This morning Bash and I went for a run - trying to stay in shape for the upcoming more difficult parts of the trip. The next few days we will attempt to get a few visas in Rabat and then travel to Casablanca in order to reship the bikes. While we have very much enjoyed Morocco, we are eager to head further south.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">March 8th, 2010</h3>
                    <p className="description text-left">Attached are a few select photos of our Morocco skiing experience. We introduced the tip stand and hand stand 360 to Morocco. The other skiers were so excited that in a few years I am sure that all of the Moroccan skiers will be doing them. For all of the recent photos, please use the web picassa link found lower down in past blog posts.</p>
          					<p className="description text-left">Rabat is one of my favorite cities thus far - it has many of the traditional features of a Moroccan city, but also includes many cosmopolitan attributes - such as Wifi. Plus, it is not very touristy and thus far nobody has even spoken to us.</p>
          					<p className="description text-left">We may spend a few more days here trying to get visas before venturing to Casablanca for our shipping attempt.</p>
          					<p className="description text-left">And finally, attached is one photo of the little green alien we keep on seeing.</p>
                  </Col>
                </Row>
                <Row>
                <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                  <CarouselIndicators
                    items={items2}
                    activeIndex={activeIndex2}
                    onClickHandler={goToIndex2}
                  />
                  {items2.map(item2 => {
                    return (
                      <CarouselItem
                        onExiting={onExiting2}
                        onExited={onExited2}
                        key={item2.src}
                      >
                        <img src={item2.src} alt={item2.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item2.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">March 10th, 2010</h3>
                    <p className="description text-left">Justus and I thought we were rough, embarking on a long and difficult journey. Unfortunately the cosmopolitan city of Morocco's capital, Rabat, has proven us otherwise. In just a few days I have become soft and tame by the copious amount of cafe au laits and pan au chocolate that I consume at an increasingly alarming rate. Fortunately the luxury and comfort that has taken over my day to day activities is not matched by our hotel arrangements, such that I both have something to complain about and something to keep me grounded to reality. This is not to say anything bad about where we are staying, in fact it is fine by all accounts. But the expectation that from this hotel one would receive soap, towels, and toilet paper would be unfounded. Yet along with our ground floor apartment comes a musty smell, a window that overlooks a well worn wall, and mosquitoes that provide better company and conversation than the hotel staff. Actually the central location and the fact that thus far none of our stuff has been stolen more than make up for any deficiencies. Go Hotel Splendid!</p>
          					<p className="description text-left">In other news Justus is winning the reading contest. I still have hopes to catch up, but I have become a bit side tracked by West Wing episodes that Jesse left on his ipod he lent me (A big thank you). The irony of watching a show on the American Executive Branch and U.S. democracy would probably be more apparent and interesting in a dictatorial country than a monarchy, but I manage to enjoy nonetheless.</p>
          					<p className="description text-left">As you have seen Justus is also winning the skiing contest. But he was the only contestant, though managed to wow all the Moroccans in the parking lot. I think I also impressed them when I gave him a ride up to the ski hill on the back of my motorcycle with his ski equipment, but the fact that I didn't fall does not mean I exhibited the same grace and athleticism.</p>
          					<p className="description text-left">We are off to dinner for perhaps couscous, tangine, or even pizza and burgers.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">March 12th, 2010</h3>
                    <p className="description text-left">While sitting in Rick's cafe in Casablanca I asked Bash, "What in heaven's name brought us to Casablanca?" He replied, "Our health. We came to Casablanca for the waters." I responded, "The waters? What waters? We're in the desert!" We were misinformed.</p>
          					<p className="description text-left">How did we find ourselves in this situation? Let's start two days ago in Rabat. After three successful visas we were looking for a fourth. Which one? I told Bash, "Round up the usual suspects." Cameroon it was.</p>
          					<p className="description text-left">We arrived at the embassy before nine. We were not optimistic and communication was difficult. I said to Bash, "Try it once, Bash. For old times sake." He replied, "If you can stand it, I can! Let's try it!" It was not to be our day and we were redirected to the embassy in Dakar.</p>
          					<p className="description text-left">I ended the discussion by saying, "You'll excuse us, gentlemen. Your business is visas, ours is running our motorcycles." Just like that we were off to Casablanca.</p>
          					<p className="description text-left">When we arrived the hotel near the Medina, the hotel manager said to me, "Heh, you know, watching you just now with the bike, one would think you've been doing this all your life." I replied, "Oh, what makes you think I haven't?" Immediately after settling in at the hotel and parking our bikes, Bash wanted to go to a cafe for a cafe au lait and a pain au chocolat. We had survived almost four hours without either. I complemented Bash, "Why Bash, I'm pleased with you - Now you're beginning to live like a Frenchman!" After exploring the great city mosque, we ventured into Rick's for the remainder of the evening.</p>
          					<p className="description text-left">Over dinner I said to Bash, "You know, Bash, I have many a friend in Casablanca, but somehow, just because you are traveling with me, you are the only one I trust."</p>
          					<p className="description text-left">He replied, "Yes Justus. It is the beginning of a beautiful venture."</p>
          					<p className="description text-left">The next morning we left for Essaouira. South again.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">March 21st, 2010</h3>
                    <p className="description text-left">The further south we drove in Morocco, the less there was. Until there was nothing. Just sand and the occasional camel crossing. The wind was at our backs and life was easy. Even the check points were hassle free. Not that our French is really good enough to be hassled though - we stare blankly when anyone asks us more than a two word question and reply that we don't speak French. A "un cadeau?" No, we are not going to "un cadeau" wherever that is, we are going to Dakhla - that way. There were few cars on the road. Beautiful sand dunes. It doesn't get much better. That is until Bash's radiator started leaking - a lot.</p>
          					<p className="description text-left">Luckily we were just entering the town of Boudjour and we quickly found a camp ground in order to have space to dissemble the area to address the problem. The source of the problem: A small rebel pebble had wedged itself between the radiator and the frame of the motorcycle and had worn a small hole. Wizard of Mechanics and Omniscient Director of all Motorcycle Questions, Mr. Ben Reeve offered his guidance: JB Weld. Bash artfully used a slice of aluminum can to patch the hole. Our first mechanical problem a success - we could not have been happier.</p>
          					<p className="description text-left">The next morning we drove to Dakhla for the kiting competition. Only Bash entered as I have the all time high score the one time I went a few years ago and wanted to give him a fair chance. He won easily. A truly wonderful place to kite - Bash gave the place a working over.</p>
          					<p className="description text-left">From Dakhla we woke up early and traveled the final few hundred kilometers to the border of Mauritania. The exit was easy enough, although the drive through no man's land surrounded by mines was beyond eerie. Particularly strange: the people living among the dunes and hundreds of blown up cars in this area. I am not sure how one gets stuck between the borders in this area, but I do not want to think about it. A most inhospitable place. The border in Mauritania was less than straight forward. The basic idea is to get everyone crossing at a similar time together in one area and stamp all of the passports at the same time. Pushing was encouraged. It worked and we moved onto the next station. We got our Carnets stamped, purchased insurance and rode the final 50ks into town.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Spain"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Spain
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Mauritania"
                      >
                      Mauritania <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Morocco;
