import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/drc/DSC00664.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00665.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00666.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00667.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00669.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00670.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00673.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00679.JPG"),
    altText: "North South Venture DRC"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/drc/DSC00683.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00681.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00686.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00687.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00697.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/IMG_4884.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00700.JPG"),
    altText: "North South Venture DRC"
  },
  {
    src: require("assets/img/countries/drc/DSC00701.JPG"),
    altText: "North South Venture DRC"
  }
];

function DRC() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/drc/DSC00664.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Democratic Republic of Congo</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Congo"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Congo
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Angola"
                  >
                  Angola <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">May 17th, 2010</h3>
                    <p className="description text-left">Established as a Belgian colony in 1908, the Republic of the Congo gained its independence in 1960, but its early years were marred by political and social instability. Joseph Mobutu seized power and declared himself president in a November 1965 coup. He subsequently changed the name of the country to Zaire. Ethnic strife and civil war, touched off by a massive inflow of refugees in 1994 from fighting in Rwanda and Burundi, led in May 1997 to the toppling of the Mobutu regime by a rebellion backed by Rwanda and Uganda and fronted by Laurent Kabila. He renamed the country the Democratic Republic of the Congo (DRC). DRC has been plagued by political instability since.</p>
                    <p className="description text-left">According to the CIA, DRC has the lowest GDP per capita of any country in the world. The population is estimated to be close to 70 million. Kinshasa is the largest city and capital.</p>
          					<p className="description text-left">Also, photography in DRC is illegal..</p>
          					<p className="description text-left">Arriving at the dock in Brazzaville ready to cross the Congo river into the DRC, we felt like Dante and Virgil crossing the river Styx into the fifth circle of hell. The water was more sombre far than perse; and we, in company with the dusky waves, made entrance downward by a path uncouth. The docking area was relatively tame at 8:00 am. We found a nice looking policeman who offered to aid us in the immigration procedures and he led us to each station and eventually helped us purchase ferry tickets. Unfortunately he also made it difficult to avoid paying a few "extra taxes" for our bikes, although the nominal amount was minimal compared to what we heard most other travelers have paid.</p>
                    <p className="description text-left">We were finished with the formalities by 9 am and the police encouraged us to board the boat, although the ferry was not expected to depart until 10 am. Seizing the opportunity to get a good position on the boat deck, we started to descend the ramp until we saw that this was not the normal car ferry and there was a flight of stairs in our path. Conveniently positioned, there were eight men nearby eager to offer their lifting services- for a fee of course. Not to be discouraged, Bash put his bike into first gear and crashed down the staircase onto the boat and found a good parking spot. I followed suit with some help from Bash. Neither of us said anything for some time to avoid bad luck, but we were both very worried about what sort of ramp we would find on the Kinshasa side.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">The passenger ferry was quiet for the first half an hour and Bash did a little reading while I engaged in the popular pastime in Central Africa - people watching. The boat, unlike standard US or European equivalents, had no luxuries at all. Everything was metal, rusting, and would certainly fail any modern safety inspection. The boat was actually two boats tied together by some frayed old rope at the bow and at the stern. There was a dining service, or rather two elderly women cooking a fish dish over open flame. All of the dock employees seemed to get their breakfasts from the two women and were continually boarding, eating, and exiting the boat.</p>
                    <p className="description text-left">At 10:00 am, the expected departure time, the crowds began to appear carrying an enormous quantity of goods for transport. Also unlike the US or European equivalent, there does not seem to be a limit on the number of people per boat. People continued to pile on until you were convinced the boat was full, and then more would board.</p>
                    <p className="description text-left">Sadly, due to a price discount for people with a disability, many of the passengers boarding the boat were blind or missing a limb. It is very likely that these handicaps are not accidental as it is a competitive advantage in the trade of goods between Congo and DRC. A very ugly side of humanity.</p>
          					<p className="description text-left">At 10:30 am, people began running towards the boat, on the boat, and the dock area turns chaotic. Soon the boat started to pull away and at which time people began jumping onto the deck from shore, shouting, and throwing things on board. By 10:45 we were off across the great Congo river. Phlegyas, Phlegyas, thou criest out in vain. Soon as the Guide and I were in the boat, the antique prow goes on its way, dividing more of the water than 'tis wont with others. Within 30 seconds some of the youths nearby started to fist fight and then chased each other around the deck and the engine room roof. The man who looked like he was in charge of the boat stopped the fight, but the atmosphere remained tense. Bash and I tried to stay out of the way and unnoticed. This was difficult as many people were leaning against our bikes, resting on our panniers, even eating their fish breakfast on my bike seat.</p>
                    <p className="description text-left">Bash and I observed that there were multiple people on board with rope whips coated in plastic. The use for these weapons we would soon find out - to get people off the boat and to manage the crowds. After battling the powerful Congo current for 35 minutes we pulled into the dock on the Kinshasa side where the atmosphere was even more hectic. Close to shore many people started jumping off the boat into the river. There is a big fence on shore to prevent people from jumping onto the boat, but people were climbing over it, shaking it violently, and running everywhere.</p>
          					<p className="description text-left">Fearful of the whips and fearful of getting stuck on the boat as people do not seem to wait to board the boat for the return trip, Bash and I edged for good position, revved our engines, and gunned it onto the dock and up the old metal ramp through the dense crowd of people. A police officer immediately stopped us and took our passports. He told us to stay where we were, but it was evident that there was no way to stay put based on the dense flow of people coming past us and he led us up to the immigration holding area. To clear the gate area into the holding area the police use their rope whips to gain enough space for us and our bikes and closed the gate behind us as the people wrestled and fought their way towards the entrance.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
          					<p className="description text-left">Safely inside the quieter immigration compound, I followed the policeman inside a building for some questioning by another gentleman who spoke better English. The line of questioning was odd. What are your parents' names? Are the alive? What are your cousins' names? Which countries in Africa have you visited? Which border did you cross between Nigeria and Cameroon? What was the town name there? Does Boston have a good communications school? How much cash do you have on you? Do you have a Visa card? Yes - all very relevant. With my answer sheet submitted the policeman ran off to stamp our passports while I tried to find the Carnets which had gone missing. I looked outside at one point and saw Bash surrounded by eight men each trying to sell something. Good thing it was my turn to deal with the passports.</p>
                    <p className="description text-left">I eventually tracked down the Carnets, got them stamped, ran back to the bikes, and we were off. Or maybe not just yet. While attempting to leave the immigration area the police had closed the gate in front of us and explained that we had not had our bikes disinfected. There were multiple people wearing lab coats telling us that they were health officials from the hospital and that we had to have our tires sprayed with a disinfectant spray before we could enter the DRC. The cost - $60 per bike. After some good waiting with Bash parked directly in the way of all other cars trying to leave, we quickly bartered the price down to $20 after claiming we both had asthma and we could not have our bikes sprayed, paid, and set off into Kinshasa just after 12:30. Knowing full well how corrupt this scam was, after speaking with other travelers it seems impossible to avoid paying something to the "health officials."</p>
          					<p className="description text-left">Getting out of Kinshasa was surprisingly easy. Although the city has over 12 million people, the roads were wide and it was easy to weave our way through traffic. With ample light left in the day, we were eager to cross the border into Angola that night if possible. As the police officers at the checkpoints seemed to prefer sitting in the shade than waving us down on the street and did not put in much effort into stopping us, we drove through every one on the way to the Angola border without stopping and made great time.</p>
          					<p className="description text-left">At 5:30 pm we pulled up to the DRC side of the Angola border and the police announced that the border was closed for the night and we would have to wait until the morning. As there were no hotels in this small village, they immigration officials told us that we were welcome to setup our tents wherever we wanted. We decided that in front of the immigration hut seemed like the best place and would ensure timely service the next morning. We set up our tents in the middle of town with the entire village watching in the final light of the day. The second to the chief of the village turned up and was wondering if we needed anything. He brought over some chairs and a lantern so that we could better enjoy our spaghetti dinner. He also wanted to hold onto our passports for the night claiming that we were past the official frontier and we were not allowed to stay unless the chief had our passports. After arguing this point for some time, it was clear that there was nothing to do but let the chief have our passports for the night and hope that we would see them again in the morning. After all, he is the chief.</p>
          					<p className="description text-left">While we were hoping that people would stop staring at us before entering our tents for the night, we were not going unnoticed and decided to retreat for the night at 8:30. The village quieted down soon after and we were able to sleep for a few hours, or until the drums and singing started at 5:00 am. We packed up soon afterwards and waited for the immigration officials to show up for work eager to enter Angola as soon as possible to make the most of our 5 day transit visas.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Congo"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Congo
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Angola"
                      >
                      Angola <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default DRC;
