import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

function Disclaimer() {
  return (
    <>
      <div className="section">
        <div className="about-description text-center">
          <div className="features-3">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto" md="10">
                  <h2 className="title">Disclaimer</h2>
                  <h5 className="description text-justify">
                  This trip is not to be taken lightly. If you wish to follow our
  						route, we hope you find some helpful information in our journey,
  						tips, and equipment area. Please feel free to contact us if you have
  						more specific questions and we will do our best to help.
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default Disclaimer;
