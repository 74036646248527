import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/angola/IMG_4869.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00702.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00705.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00706.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00711.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00725.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00727.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00729.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00739.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00757.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00762.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00763.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00765.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00766.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00768.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00777.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00796.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00811.JPG"),
    altText: "North South Venture Angola"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/angola/DSC00815.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00826.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00832.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00833.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00836.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4969.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00843.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00845.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/DSC00846.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4875.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4893.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4905.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4935.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4951.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4954.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4971.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4976.JPG"),
    altText: "North South Venture Angola"
  },
  {
    src: require("assets/img/countries/angola/IMG_4997.JPG"),
    altText: "North South Venture Angola"
  }
];

function Angola() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/angola/IMG_4875.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Angola</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/DRC"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> DRC
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Namibia"
                  >
                  Namibia <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">May 18th, 2010</h3>
                    <p className="description text-left">Angola was a Portuguese overseas territory from the 16th century to 1975. After independence, Angola was the scene of an intense civil war from 1975 to 2002. Angola has been living a peaceful and stable environment since 2002 when a peace agreement was signed with UNITA, the main rebel movement. Progress has been made towards democracy, and in September 2008 the first legislative elections were held after 16 years.</p>
                    <p className="description text-left">The country is the second-largest petroleum and diamond producer in sub-Saharan Africa and the largest oil exporter to China; however, its life expectancy and infant mortality rates are both among the worst ranked in the world. Much of the country's infrastructure is still damaged or undeveloped from the 27-year-long civil war. Remnants of the conflict such as widespread land mines still mar the countryside even though an apparently durable peace was established after the death of rebel leader Jonas Savimbi in February 2002. Subsistence agriculture provides the main livelihood for most of the people, but half of the country's food must still be imported. Since 2005, the government has used billions of dollars in credit lines from China, Brazil, Portugal, Germany, Spain, and the EU to rebuild Angola's public infrastructure.</p>
          					<p className="description text-left">Luanda is argued to be the most expensive city in the world.</p>
          					<p className="description text-left">Our Angola transit visas only allowed us five days to traverse the country. This is no easy task as Angola is an enormous country. On a very tight schedule, we were optimistic that sleeping at the border would facilitate an early morning departure. This was not to be the case. After packing up our tent at 6:00 am, Bash and I waited outside the DRC immigration office for the police to arrive. 6:00 am turned into 7:00 am, and 7:00 am turned into 8:00 am. Nobody turned up for work. Lots of people were leaving the village though. Very worried about our passports at this point, we began asking anyone who would speak with us if they knew where Nastor was, the second to the chief and the man who had taken our passports. Everyone said that he had left for the border and we should drive down the road to find him. Now very confused about where the immigration office actually was and even more alarmed about our missing passports we started off down the road towards Angola stopping at all official looking buildings on the way.</p>
          					<p className="description text-left">We eventually found the Duane building and had our Carnets stamped. We again asked where Nastor was and were told to drive even further. After another mile we arrived at the correct border control facilities. When we were asked for our passports and were unable to produce them, immigration officials were perplexed. We told them that Nastor had them, hoping that this was actually a person at this point. Even more confused, they officials tried to figure out how this was possible. Were we coming from or going to Angola? Going to. Then where are your passports? Nastor has them? How is this possible? You tell me - Where is Nastor? Who is Nastor? On no - he is the chief, you know Nastor? Ah yes, Nastor! Yes - he has our passports. After five minutes of "Who's On First" we tracked down Nastor and our passports.</p>
          					<p className="description text-left">While we expected the immigration procedures to go smoothly considering we had camped next to and socialized with the immigration officials the night before and they had our passports all morning, they were not as friendly at the border and we had to endure a similar interrogation that I had received upon entry to the DRC. The most comical part was the multiple inquires into where we had slept in the DRC. We would reply that we had slept at the village just down the road, but since we didn't know the name of the village, we would just point in the general direction. They would stare at us and then a group of men would discuss the situation until one would realize that we were the same people they were watching as we set up our tents the night before. At 9:00 am we were off again and quickly began the reverse procedures on the Angola side.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">After another frustrating two hours on the Angola side of the border, we gave a wave and shouted "Bom Dia" to the final police checkpoint and set off to pass through Angola as quickly as we could. Bash was so happy he sacrificed a chicken. That night, with nowhere to stay as hotels are almost non-existent in northern Angola and bush camping is unwise due to the presence of land mines, we camped behind a pink school where we thought nobody would see us. Some teenagers showed up soon after to observe us set up our tents and eat our Snickers bar dinner. Yes, we had run out of food. We were also about to almost run out of fuel as well. The next morning it was very evident that there was no way we were going to make it to Luanda without filling up with gas. While we generally carry lots of gas to avoid these situations, we had already used our extra gas and had no choice but to buy gas in water bottles on the side of the road. And we regretted it.</p>
                    <p className="description text-left">We reached Luanda, Angola's capital and most expensive city in the world just after 1:00 pm. While touring the island jutting out from downtown, Bash's motorcycle mysteriously shut off. And did not start again. While not anyone's first choice of places to break down in Africa, we didn't have a choice but to strip Bash's bike apart to find the problem right there on the side of the road. While I kept an eye on our gear and entertained the endless stream of inquisitive pedestrians with my nonexistent Portuguese, Bash replaced fuses, spark plugs, the gas filter, drained the gas lines, and more. By 5:30 pm, we had not solved the problem, but we were losing daylight and we needed to find a better place to work on the bikes in the dark. Our friend Aaron had kindly allowed us to stay with him at his hotel in Luanda, and we packed up our gear and set off to find the hotel.</p>
                    <p className="description text-left">Well, it was not quite as easy as that considering that Bash's bike would not turn on. Our solution - to tow Bash and his bike to the hotel. Sounds simple, except we did not know where the hotel was and downtown Luanda turns out to have a lot of traffic, especially at 5:30 pm. The first few people we asked were convinced that the hotel was all the way across town, so Bash put his hazards on and I honked my horn while I towed Bash 15 feet behind me all the way across the city. People stared at us in disbelief. It was an incredible scene as we inched along through the dense traffic trying to avoid accelerating and decelerating too quickly. When we reached where we thought the hotel was, our directions turned out to have been faulty and the hotel was actually back where we started. So back we returned. The police at the intersections were even more perplexed when we drove back through town - notorious for hassling travelers, they did not even stop us - I don't think they had any idea what to make of us.</p>
          					<p className="description text-left">We eventually found the hotel and Bash went to work on his bike again while I worked on finding Aaron and some food for Bash. At 8:00 pm Bash tried the ignition and the bike sputtered and then roared to life! Followed by the subsequent "Whoooohhhhoooo!!!!!!" from Bash. The problem - bad gas from the water bottles had clogged up the fuel intake system. He drained the fuel tank and added new good gas. Another success! Unfortunately we had drained Bash's battery in the process and we needed to find some jumper legs in the morning.</p>
          					<p className="description text-left">Exhausted, we fell asleep in Aaron's hotel room thrilled that we would be able to continue on the next day. A huge thank you to Aaron for allowing us to stay with him and also helping us sort out our chaotic motorcycle woes! As Bash and I commonly say along our venture, EIOFA. Three more days left to get across Angola, plenty of time as long as nothing else goes wrong.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">May 20th, 2010</h3>
          					<p className="description text-left">Before leaving Luanda we had to tackle a few simple challenges. As we have found thus far along our venture, nothing turns out to be simple in Africa, and finding a way to jump start Bash's bike was more difficult than we had foreseen. With Aaron's help we found two mechanic shops. Neither had jumper cables. Luckily, there was an auto shop nearby which sold the cables and we soon had Bash's bike running again. We quickly said our goodbyes and were off. Three days left to exit the country.</p>
                    <p className="description text-left">The day's drive along the coast was beautiful and easy. The tarmac was new and very smooth. We spent the night in Benguela at a quiet little hotel. As we had made such good progress across Angola we were optimistic that we would be able to reach the border in time.</p>
                    <p className="description text-left">Jack Sprat could eat no fat. His wife could eat no lean. When our mechanical problems started again the next day we did not exactly lick the platter clean. Two hours into our drive, my chain fell off the rear sprocket. After a month of worry and loving attention to the device, it was looking extremely thin. After quickly reattaching and tightening the chain we were off again. Unfortunately due to the tragic state of my sprocket, I was forced to drive extremely slowly to avoid another incident.</p>
          					<p className="description text-left">A few hours later Bash's temperature warning light came on. The quick diagnosis: a broken fan. Not desired considering the distance we still had to cover to Namibia. Fortunately, as long as Bash was able to keep sufficient speed, the air flowing past the engine would suffice. This leads us to our great predicament. I was forced to drive extremely slowly and Bash extremely quickly. Bash would speed off trying to avoid major potholes and after five or ten minutes find a good rest spot in the shade to wait for me. I would eventually catch up and would repeat the process. Repeat the process for two days terrified the entire time that Bash's temperature light would come on again. It didn't.</p>
          					<p className="description text-left">With a little luck, we eventually made it to the Namibian border, breathed a massive sigh of relief, or maybe multiple sighs of relief, drove to a cute little guest farm, ate our first complete meal in weeks, stared into space in shock that we had actually made it to Namibia, and slept hour after hour after hour. It was truly a wonderful feeling.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/DRC"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> DRC
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Namibia"
                      >
                      Namibia <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Angola;
