import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/zimbabwe/DSC01132.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/DSC01140.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/DSC01144.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/DSC01148.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/DSC01155.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/DSC01165.JPG"),
    altText: "North South Venture Zimbabwe"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/zimbabwe/DSC01181.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/DSC01182.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/DSC01197.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/IMG_5352.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/IMG_5356.JPG"),
    altText: "North South Venture Zimbabwe"
  },
  {
    src: require("assets/img/countries/zimbabwe/IMG_5359.JPG"),
    altText: "North South Venture Zimbabwe"
  }
];

function Zimbabwe() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/zimbabwe/DSC01144.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Zimbabwe</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Zambia"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Zambia
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/South Africa"
                  >
                  South Africa <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">June 22nd, 2010</h3>
                    <p className="description text-left">In 1961 Rhodesia formulated a constitution that favored whites in power. In 1965 the government unilaterally declared its independence, but the UK did not recognize the act and demanded more complete voting rights for the black African majority in the country. UN sanctions and a guerrilla uprising finally led to free elections in 1979 and independence (as Zimbabwe) in 1980. Robert Mugabe, the nation's first prime minister, has been the country's only ruler (as president since 1987) and has dominated the country's political system since independence. His chaotic land redistribution campaign, which began in 2000, caused an exodus of white farmers, crippled the economy, and ushered in widespread shortages of basic commodities. In April 2005, Harare embarked on Operation Restore Order, ostensibly an urban rationalization program, which resulted in the destruction of the homes or businesses of 700,000 mostly poor supporters of the opposition. President Mugabe in June 2007 instituted price controls on all basic commodities causing panic buying and leaving store shelves empty for months.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                    <p className="description text-left">The power-sharing government formed in February 2009 between Mugabe and Tsvangirai as prime minister has led to some economic improvements, including the cessation of hyperinflation by eliminating the use of the Zimbabwe dollar and removing price controls. The economy is registering its first growth in a decade, but will be reliant on further political improvement for greater growth.</p>
                    <p className="description text-left">All I wanted to do now was get back to Africa. We had not left it, yet, but when I would wake in the night I would lie, listening, homesick for it already. The decision to drive through Zimbabwe was unspoken. Also not on our original agenda, we were both intrigued to see what had become of the former mystical Rhodesia. We entered the country at the edge of Lake Kariba and were immediately back in the Africa we missed.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
          					<p className="description text-left">As the next day's drive was short, we decided that it would be an opportune time to After getting our passports stamped we were encouraged to visit a nearby building where a lady wrote down our vehicle registration details and then asked if we had brought anything from Zambia for her. I replied, "Yes, some good luck and this big wide smile." This seemed to satisfy her, as indeed such an answer should have. Her question satisfied us, as indeed we missed practicing our now worn corruption avoidance tactics.</p>
                    <p className="description text-left">The most comical moment at the border occurred during our final discussion about the road tax payment. Since the only USD that had survived the trip were coated with mud, the border official initially did not want to except the currency. We explained that if they simply washed the mud off the dollars they were of exceptional quality. After much discussion, a nice gentleman exchanged our bills for mud free bills. As we were to receive eight dollars in change, we started giggling when all of the one dollar bills we were given were even dirtier than the original money we tried to pay with.</p>
          					<p className="description text-left">Unsure of what to expect, we were struck with how beautiful the land of Zimbabwe is and how developed the cities are. Both factors make the county's recent political woes all the more shocking. Huge sophisticated farms - abandoned.</p>
          					<p className="description text-left">The road to Harare, although in need of repair, was decent and progress was rapid. We reached the center of the city quickly and began our search for a working ATM and a place to stay. As tourists stopped coming to Zimbabwe in 2005, accommodation is difficult to find and most hotels have gone out of business. We did not have any luck finding an ATM either.</p>
          					<p className="description text-left">Eventually we found a nice family that offered us their lawn for the night for ten euros and we graciously excepted. We had a few remaining US dollars so we went to the supermarket to purchase some food for dinner. As the former Zimbabwe dollar is no longer in circulation, USD is Zimbabwe's new currency, although there are very few coins in circulation. Since change is not available, one must plan one's purchases carefully to minimize rounding loss.</p>
          					<p className="description text-left">Our final stop in Zimbabwe was at the old Zimbabwe ruins. The Great Zimbabwe is a ruined city that was once the capital of the Kingdom of Zimbabwe, which existed from 1270 to 1550 during the country's Late Iron Age. Great Zimbabwe acted as a royal palace for the Zimbabwean monarch and would have been used as the seat of their political power. They are also the second largest ruins in Africa, second only to the pyramids.</p>
          					<p className="description text-left">With one more day until the start of the World Cup, and eager to get our final border crossing over with, we sped off the following morning towards South Africa - our 20th and final country on North South Venture.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Zambia"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Zambia
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/South Africa"
                      >
                      South Africa <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Zimbabwe;
