import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/ghana/DSC00820.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00826.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00834.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00838.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00844.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00851.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00870.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00896.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00898.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00901.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00910.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00913.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00931.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00932.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00938.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00955.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00960.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00964.JPG"),
    altText: "North South Venture Ghana"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/ghana/DSC00971.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00984.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC00999.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/DSC01001.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4392-1.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4398.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4426.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4437.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4450.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4455.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4468.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4486.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4488.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4515.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4523.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4527.JPG"),
    altText: "North South Venture Ghana"
  },
  {
    src: require("assets/img/countries/ghana/IMG_4530.JPG"),
    altText: "North South Venture Ghana"
  }
];

function Ghana() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/ghana/DSC00960.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Ghana</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Burkina Faso"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Burkina Faso
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Togo"
                  >
                  Togo <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">April 9th, 2010</h3>
                    <p className="description text-left">"Hey Bash, look they have computers!" "And the guards are wearing loafers!" The Ghana border was a magical place - complete with technology, organization, and fine dress. We hadn't seen this since Spain. We were giddy with excitement and forming spectacular expectations of what the rest of the country would look and be like. "And the border crossing is logical!" Yes, all of the necessary stations were in order and close to each other - no inquires to random pedestrians required. Such luxury. And speed - we were in Bolgatanga in time for breakfast. After a quick stop at the Barclays ATM, we dinned on toast, jam, and coffee at 9am, content with the day's successes.</p>
                    <p className="description text-left">While we originally planned on spending our first night in Tamale, the absence of internet at the nicest hotel in the city where we picked through our somewhat odd and boney lunch had us dismayed, and the draw of the Monkey Sanctuary was too powerful - we decided to charge onward. The trip was long and the road hot. The scenery - incredible. As the time ticked away, we slowly realized this would be the longest day of our journey - thus far. We drove over beautiful rivers, through dense forests, and slowed for heavily populated and lively villages. We rode the last 32km on a dirt road to the Monkey Sanctuary in the twilight, arriving 20 minutes before sunset - humbled again by Africa, but unable to sleep due to our excitement about visiting the Monkey Sanctuary in the morning.</p>
                    <p className="description text-left">The Monkey Sanctuary turned out to be not exactly what the name implies. It is a sanctuary in the sense that the villagers interact with and feed the monkeys daily. It is a sanctuary because it is a place where humans and non-human primates could live together in harmony (according to the commentary written on our guide's shirt). Eager to be present for the early morning feeding, we hired a guide and set off to find the monkeys at 8 am.</p>
                    <p className="description text-left">Now it took Bash and I some time to get used to this idea of feeding the monkeys. In most places, or all places I have been, feeding the wildlife generally destroys the ecosystem and permanently alters the wildlife's behavior. The Monkey Sanctuary must have discovered a long time ago that visitors much prefer interactive tours than passive tours, so they have learned to thoroughly incorporate the monkeys into the tour. Stop one and two were to the peanut and banana stations. Stop three was to find the two different species present: the Mona monkey and the black and white Colobus. Now the Colobus were scared of humans, so our attention quickly shifted to the Mona monkeys. Although Bash and I initially felt very uncomfortable about actually feeding the monkeys, we decided that we may as well indulge as the activity had been taking place for years and it was far too late to change now. The poor rationale helped us feel better immediately.</p>
                    <p className="description text-left">Direction: place ten peanuts into the palm of the hand and hold out to the monkey of choice - which tended to be the alpha male as he would chase away most competitors. The monkey would near, grab the peanuts, and immediately consume them. Repeat a hundred times. As the tour continued it was also clear that visitors must get bored after a while, so the act of feeding must get more and more exciting. So out come the bananas. Usually held around waist to chest levels in order to encourage lots of jumping. Or climbing on the clothes.</p>
                    <p className="description text-left">Feeling a mix of pleasure from feeding the monkeys and guilt from aiding in the destruction of an ecosystem, we returned to our accommodation. We quickly packed up and headed for Kumasi - completely forgetting that it was Friday - Good Friday. More on this in Ghana Part #2. To be continued.</p>

                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">April 10th, 2010</h3>
                    <p className="description text-left">Half a league, half a league, half a league onward, all the valley of heat rode the two bikers. Forward to Dixcove!</p>
          					<p className="description text-left">Our day on Good Friday seemed to be planned well. The plan was simple: to ride two hours to Kumasi, find a good hotel, use the internet, eat a great meal, and enjoy the grand market. We failed to realize two important details. First, it was Good Friday. Second, what the roads would be like on Good Friday. We learned quickly.</p>
          					<p className="description text-left">Shortly after leaving the Monkey Sanctuary we happened upon a game of football right next to the road. As the ride was supposed to be short that day, we watched a little of the game and decided it was an opportune time to do a little bike maintenance. With so many eager helpers around it is very easy to get one's bike onto the kick stand as well as obtain some protection from general road traffic. We all had a lot of fun cleaning the motorcycle chains, although the helpers were very sad that we would not let them take the bikes for a spin afterward.</p>
          					<p className="description text-left">Arriving in Kumasi a few hours later it was very obvious that it was some sort of holiday. The streets were packed with cars, people, animals, food, goods, and anything you could imagine! Against Bash's advice at a fork in the road close to the center of town, I encouraged us to go left instead of right and wish I hadn't. The left turn led us directly into the enormous market in the center of town which had already turned into a parking lot for the day. There are few situations more torturous on a motorcycle than being stuck in heavy traffic when the temperature is over 100 degrees. As Bash and I both wear long coats with pads, helmets, and long pants for safety, we are not well adapted for extended time in extreme heat when not moving. After an hour of pushing the bike through traffic and politely asking cars if they could move a foot here or there, we found a way back out to less crowded roads, minus the gallon of water we lost in the heat. We ravenously searched for a cold drink to rehydrate. Bash drank a litre and a half orange Fanta as we sat on the ground outside of the gas station looking slightly like the monkeys we had fed that same morning.</p>
          					<p className="description text-left">Not eager to venture back into the city with so much chaos and so many people on the street, we opted to continue on towards Kakum National Park near Cape Coast. After inquiring for directions five times, we were fairly certain we had found the correct road. Unfortunately, hadn't.</p>
          					<p className="description text-left">As we drove on the road deteriorated. The potholes grew monstrous, the sections of dirt track increased, our progress slowed, the heat intensified, and the daylight faded. At 4pm it was clear that we had taken the wrong road and there was no way we would make it to Kakum National Park that night. There normally would have been multiple housing options along our route, but as Good Friday progressed, more and more excited people flooded the streets making it very difficult to travel through towns and even more difficult to find any sort of hotel. At 5pm we arrived in Tarkwa, a town we thought would have some decent lodging options, but it turned out to be an old mining town with some particularly savory and intoxicated characters. Even stopping on the side of the street for a rest was decidedly unpleasant. Our only remaining option - the Turtle Bay Lodge in Dixcove - 80km away. With some luck, the road finally improved and our progress increased rapidly. It was the best road we have seen since Morocco.</p>
          					<p className="description text-left">Just after 7pm the two sweaty and exhausted travelers pulled into the Turtle Bay Lodge and found a beautiful spot next to the beach to set up our tent. It was an absolute paradise. After the two longest days on our trip, we spent our little remaining energy eating dinner and trying not to disturb the other guests in our grimy state. We looked like animals. It did not help when Bash asked the Turtle Bay owner sitting at the next table if she was going to eat the remaining food on her plate. At least he used the silverware. We desperately needed some rest.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">April 12th, 2010</h3>
                    <p className="description text-left">We took 4 days at Turtle Bay Lodge to properly recover from our trip from Dakar.</p>
          					<p className="description text-left">The Turtle Bay Lodge serenely contrasted the harsh chaos embodying the long road there. That night as we entered our tents, a haze rested on the low shores that ran out to sea in vanishing flatness. We slept uninterrupted hour after hour.</p>
          					<p className="description text-left">In the morning I went for a long run and swim. The pleasant water gradually stripped the grime and bike grease from my hands which were stained from the daily bike maintenance. The delicious breakfast prolonged the morning's enchantment as the eggs and toast were divine. The accompanying coffee was actually brewed and mixed with real milk as opposed the now familiar Nescafe and powdered dairy product. We finished breakfast with another long swim until it became clear that our pale white skin was not going to survive the midday equatorial sun. We spent the afternoon reading and socializing in the shade, eating falafel sandwiches, and resting for the evening's volley ball tournament.</p>
          					<p className="description text-left">We met three wonderful South African couples that are traveling a very similar route, although they started in the UK. Succumbing to the innate sense of competition between other overlander groups, the evening's volley ball game was fierce. I admit that I lost. My record at sporting competitions is rapidly deteriorating since our time in Morocco. We finished the day with a final swim and admired the newly acquired red tint to our skin.</p>
          					<p className="description text-left">The next few days at Turtle Bay were equally relaxing. We spent some time exploring a nearby village on the coast and made a few friends, purchased some fruit, and ensured that Chelsea was beating Manchester United at the local sports bar (we are big fans as Bash has a Chelsea sticker on his pannier - I had one on the pannier that now lives in Mauritania).</p>
          					<p className="description text-left">By the fourth night we agreed that we should venture to Accra before the end of the week to try to tackle the Nigerian visa problem and to ensure that we had enough money to cover the bill we were quickly accumulating at Turtle Bay. I spent the last evening losing my final intense volleyball game, this time with a fun group of Canadians, British, and other Americans we had met.</p>
          					<p className="description text-left">On the way to Accra we spent a night visited the fort at Elmina, ate lunch in Cape Coast, and slept at the Hans crocodile pit near Kakum. The old slave fort was fascinating, but also shockingly haunting. We arrived in Accra on Wednesday morning and spent much of the day trying to find the Nigerian Embassy and also attempting to avoid suffering from heat stroke in the traffic. By the time we found the embassy we were too late and they told us to come back the next morning. They also gave us a list of essential paperwork which we frantically compiled in the comfort of the air conditioning Novotel Hotel. At 5:30 we met our now good friend Jenny who was leaving work at the US Embassy and she led us to her house nearby. Back into paradise again.</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">April 14th, 2010</h3>
                    <p className="description text-left">Imagine driving across Africa for weeks only to make a right hand turn and discover that you drove straight onto Newbury Street in Boston. It would be quite peculiar. We had a similar sensation walking into Jenny's house. It was modern, it had air conditioning, food from Trader Joes, normal beds, sushi, laundry, Legally Blond, He's Just Not That Into You, House, Special K, and more. Yes, air conditioning. Blissfully cool, and we took full advantage. We also successfully caught up on email, although we have recently found out that many of the emails we sent in Accra have not reached their recipients, so if anyone was expecting an email from us and has not received it, it is lost in email land - we have tried, but cannot figure out the problem.</p>
					          <p className="description text-left">Thursday morning we returned to the Nigerian Embassy weighted down by the excessive paperwork required. We picked them up on Friday - success, although I received 30 days and Bash only received 14. They must have liked me better. Another piece of the puzzle solved. 5 of the 9 difficult visas obtained, although Angola remains and is the most difficult. Feeling top notch, we headed to the bowling alley to celebrate with a few games of pool and some Chinese food.</p>
                    <p className="description text-left">Saturday was Jenny's birthday, so Bash cooked some exquisite chocolate chip banana pancakes for breakfast and we prepared for her big birthday bash that evening. We spent the evening socializing with other expats and losing the sleep that we had been methodically saving up over the past week.</p>
					          <p className="description text-left">In the morning we headed out for Wli falls, the Togo border, and the third of four large sections of our journey.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Burkina Faso"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Burkina Faso
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Togo"
                      >
                      Togo <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Ghana;
