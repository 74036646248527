/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Footer() {
  return (
    <>
        <footer className="footer" data-background-color="black">
        <Container>
          <Row>
            <Col className="mr-auto ml-auto" md="10">
              <div id="copyright">
                © {new Date().getFullYear()}, North South Venture
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
