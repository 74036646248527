import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/cameroon/DSC01240.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01243.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01246.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01250.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01253.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01254.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01257.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01263.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01268.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01269.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01272.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01282.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01289.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01311.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01320.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01333.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01338.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01342.JPG"),
    altText: "North South Venture Cameroon"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/cameroon/DSC01345.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01346.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01348.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01353.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01375.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01378.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01386.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01391.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01394.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01400.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01405.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01412.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01415-1.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01419.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01421-1.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/DSC01422.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/IMG_4636.JPG"),
    altText: "North South Venture Cameroon"
  },
  {
    src: require("assets/img/countries/cameroon/IMG_4650.jpg"),
    altText: "North South Venture Cameroon"
  }
];

function Cameroon() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/cameroon/DSC01254.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Cameroon</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Nigeria"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Nigeria
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Gabon"
                  >
                  Gabon <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">April 30th, 2010</h3>
                    <p className="description text-left">The next day was an easy three hour drive through Ikom to the border. The border on the Nigerian side was very straight forward. The Cameroon side was equally as pleasant, although we spent much of the time talking about who was going to win the world cup. Since we are still not sure which countries are even in the world cup, we let the immigration officials do most of the talking. Not surprisingly they were very sure Cameroon was going to win. Un cadeau? No, no cadeau today.</p>
                    <p className="description text-left">After driving up the road from the immigration office to the passport control office in Cameroon, I tried to drive over a small speed bump next to the passport control office and realized that it was a little higher than my bike could safely clear. I was stuck - back tire off the ground. After drawing lots of attention by revving the engine and shaking the bike, a few pedestrians ran over to help me over. Good thing I didn't draw any attention to myself. Passport control was easy as well and by 1 pm we were off again.</p>
        					  <p className="description text-left">The next road section to Mamfe is notorious. Although it is only 72km, we heard one story of it taking a group 13 days. It turns out that they had been repairing the road over the past year and it was in great shape. We were zipping along in no time when Bash's front tire went flat. I was very excited because we hadn't had a flat tire yet, but Bash was less thrilled. We found a little clearing on the side of the road to examine the problem. After scouring the tire, we could not find any source of the leak and decided to fill it with air and continue on to Mamfe. The leak was slow and we only had to re-inflate it once more on the way to town. That evening at the Data in Bash found the source of the problem, a small thorn, which we think entered the tire back in Senegal. Very slow leak indeed. Bash also noticed that his bike was leaking oil. Not a good sign. His bottom oil plug had a small crack in it, but it seemed to be a slow leak, so hopefully it would last until Yaounde.</p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                  <h3 className="title text-left">May 1st, 2010</h3>
                    <p className="description text-left">We crossed the Nigerian border on Sunday and spent the last two days successfully climbing Mount Cameroon. Will catch up the blog this weekend. Exhausted again. Hopefully soon we can find some quality internet? Maybe right after we find running water..</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">May 3rd, 2010</h3>
                    <p className="description text-left">To go to Buea or to go to Yaounde? I am not sure where the great idea to climb Mount Cameroon came from, but it seemed like a good one. To Buea it was. We are still not sure how to pronounce the name of the town. We set off bright and early from Mamfe to meet our motorbiking friends Ian and Mick at the base of Mount Cameroon for the grand ascent.</p>
                    <p className="description text-left">The first 100km from Mamfe to Bamenda were unusual. They were in the process of building a major highway and judging by the number of Chinese workers doing the skilled labor, a Chinese company had been contracted to do it. The old road was mostly abandoned so we spent the first few hours weaving around huge construction vehicles and trying to avoid getting in the way. The section concluded with a muddy, rough, and torturous mountain pass where we refined our off-road riding ability.</p>
          					<p className="description text-left">Bamenda was a charming mountain town. Our transition there was not smooth. After filling my bike up with gas, I announced to Bash that I had no way to pay for it. So Bash ran off to find an ATM while I	waited at the gas station as collateral. As is normal in most of the places we had recently visited, we immediately draw a crowd. After chatting for a half an hour with all of my new friends, I decided with so many helpers around it would be an opportune time to clean my bike chain. They all helped pull the bike onto the kick stand and I unpacked my cleaning supplies. The audience stared in awe as I worked away.</p>
          					<p className="description text-left">Bash returned after 45 minutes, we finally paid, said our goodbyes and drove away. The next part of the drive was exceptionally beautiful. Lush, green, mountainous, friendly, and beautiful, Cameroon was quickly becoming our favorite country in the area. The sun was fierce, the land seemed to glisten and drip with steam. We spent the remainder of the day winding down the pristine tarmac on our way to the Paramount Hotel in Buea.</p>
          					<p className="description text-left">We arrived at the Hotel just before dark where we met Paul, our guide for the trek, as well as our fellow motorbike friends and Jose, a Spanish bicyclist who wanted to accompany us on the newest adventure. After briefly discussing our route and food, we went to sleep early to gain our strength for the hike.</p>
          					<p className="description text-left">We left the Hotel at 6:30 am and all squeezed into a taxi to drive us to the base of the mountain while our hired backpacks dangled precariously out of the small trunk. Fortunately we were good packers and all of our equipment survived the drive. The first part of the trail was through the jungle. Dense forest where I was constantly on the lookout for snakes. Thankfully I did not see any. The forest opened up to grassy meadows and then the trail steepened to gravel, jagged volcanic rock, and slippery grass. We reached the hut where we would spend our first night at just before 4pm.</p>
          					<p className="description text-left">A 23 year old Swiss traveler showed up at our hut for the night and we all exchanged stories and shared our pasta dinner over two bottles of red wine while it poured rain outside. As we were supposed to wake up at 4 am for our summit attempt, we all went to bed around 8pm. And that was when it started again.</p>
          					<p className="description text-left">Now before I relate what happened that night I need to take you back 12 years to 1998. I spent the summer on the Juneau Icefield up in Alaska. For a two week stretch of the summer I lived in a place called camp 26 where we helped construct a new building and conduct glacial cave mapping. At camp 26 we also entered what I always liked to refer to as, "Willard War I." Willard is a name I gave to the largest of all of the rats inhabiting the camp. Willard was uncatchable. This night on Mount Cameroon we entered, "Willard War II." It began subtly enough. There was some rustling of plastic. Some scurrying of feet. Then came the main assault. I had left my backpack open hanging on a hook on the other side of the room. Around midnight I heard a rat drop down from the hook into my backpack and begin to attack my biscuits. As those biscuits were very dear, I fought back. Hopping out of bed I ran over to my backpack and started to wave my flashlight and make grunting noises. The rat, which looked to be more a brown mouse, not a New York City subway rat or anything in case you were wondering, just stared at me and then went back at the biscuits. I tried to poke the rat with my flashlight, but was not willing to sufficiently risk my health. I finally decided to shake the bag and the rat fell to the floor. Right next to my feet. Good thing I didn't have any shoes on. I jumped away in fright and hoped none of the other guys saw me jump away from the cute little rodent. Luckily, the beast decided to run away while I pondered what to do next. After zipping up my bag I attempted to go back to sleep. It is not easy to sleep when you know your room is infested with rats.</p>
          					<p className="description text-left">We woke up around 6 am and all compared our rat stories. Mick had his music on all night and didn't even notice the war. Ian's sleeping bag zipper was broken and a rat managed to crawl in. Our Swiss friend had slept in a separate room and had had it worse than everyone and just shook his head. He didn't look like he had any sleep that night. At 7 am we returned to the task at hand - the summit! Onward!</p>
          					<p className="description text-left">The hike from the hut to the summit was disappointingly short and we were on top by 9:30. I say disappointingly because if the distance had been further it would have better justified our time with the rats. The view was obstructed by the intense fog and 60mph winds. We did not spend long on the top, but were proud to have made it.</p>
          					<p className="description text-left">Mount Cameroon is the highest Mountain in Western Africa. Pleased with our ascent effort, we were less enthusiastic to hear that they run a race to the top every year and the top time is under four hours. A success all the same.</p>
                  </Col>
                </Row>
                <Row>
                  <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                    <CarouselIndicators
                      items={items2}
                      activeIndex={activeIndex2}
                      onClickHandler={goToIndex2}
                    />
                    {items2.map(item2 => {
                      return (
                        <CarouselItem
                          onExiting={onExiting2}
                          onExited={onExited2}
                          key={item2.src}
                        >
                          <img src={item2.src} alt={item2.altText} />
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item2.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next2();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="10">
                    <h3 className="title text-left">May 5th, 2010</h3>
          					<p className="description text-left">The next morning we hobbled out of bed and began our journey to Yaounde to get the last and final visa: Gabon.</p>
                    <p className="description text-left">Post arrival in Yaounde we entered the World Championships of Visa Gathering. We were seeded very highly for this competition as we had been so successful with all of our prior visas. As Gabon was the last visa we need to arrange prior to entering a country, we knew it would be an especially difficult one. It was. When we showed up to the embassy on Thursday just after one o'clock in the afternoon, we were very careful to wipe off our feet to leave a good impression upon entry. They told us we had to come back the next morning for our visa submissions. Disappointed, we found the Presbyterian Mission where we would spend the next three nights as well as improve our waiting techniques.</p>
          					<p className="description text-left">With some free time, Bash and I waved down a taxi and went to go find an ATM and a patisserie. Yaounde, although lacking in other types of basic infrastructure is home to some fabulous patisseries complete with exceptional pain au chocolats and gourmet pizza. Not that I do not find food in central Africa to be appetizing, but one tires quickly of chicken and rice and generally craves Western food at all times - pizza especially.</p>
                    <p className="description text-left">Friday morning we returned to the Gabon Embassy and submitted our visa applications. They told us to come back at 3:30 pm. At 3:00 we made our way back to the Embassy optimistic that we would receive our passports complete with visas. I had a slight communication problem with the taxi driver taking us to the Embassy and we had a little site seeing tour of the city on the way. Ian and Mick, who had joined our team in the Visa Gathering Championship were less than thrilled that I had chosen that time for our site seeing tour, but we finally arrived at the embassy at the set time. Then the waiting began. As it was the World Championships, there were many other participants waiting outside with us and every time any embassy employee would open the door we would all stand up and walk over with high hopes. At 5:00 pm the cars started leaving the embassy, although nobody had clearly told us whether we would be getting our visas back. We assumed somebody would eventually tell us 	to go home if they would not be ready. So we just kept on waiting. Around 6pm it was clear that all the embassy employees had left and we were not getting our visas that day. The guard optimistically told us to come back at 10 am the next morning and they would be ready. Although I have never heard of an embassy being open on a Saturday, there are few rules in the World Championship and one just has to accept the change in the rules.</p>
          					<p className="description text-left">The next morning we arrived at the embassy at 9 am ready to begin our waiting. Since the sun was now in a different location in the sky, we chose the other side of the street to sit and wait. Many of the same participants from the day before were back, although we had lost some in the Super Final. At 10:30 a man from the Embassy came up with a piece of paper and told us to write our names on it.	Encouraged by the first sign of action we cut to the front of the line and rapidly added our names to the list. At 11:00 the competition concluded and the awards were given. I came in first place with the first returned passport and a 3 week visa. Bash came in second, but was only given 5 days. They most like me more again. Ian almost did not get a prize, but someone finally found his passport in the embassy and it was returned complete with the Gabon visa.</p>
          					<p className="description text-left">As Saturday was 1 May, most inhabitants of Yaounde were consuming large quantities of alcohol. Eager to avoid the streets later in the afternoon, we returned to the ATM and prepared for our venture south the next day. After a spectacular lightning storm that night and torrential downpours, we left early in the morning on Sunday for the Gabon border. Bash managed to survive the night better hydrated than he had hoped.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Nigeria"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Nigeria
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Gabon"
                      >
                      Gabon <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Cameroon;
