import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

// carousel 1 items
const items1 = [
  {
    src: require("assets/img/countries/mauritania/DSC00532.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00539.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00549.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00552.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00554-1.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00555-1.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00558-1.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00559-1.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/DSC00561-1.JPG"),
    altText: "North South Venture Mauritania"
  }
];

// carousel 2 items
const items2 = [
  {
    src: require("assets/img/countries/mauritania/DSC00564.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/IMG_4099.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/IMG_4103.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/IMG_4104.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/IMG_4108.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/IMG_4112.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/IMG_4121.JPG"),
    altText: "North South Venture Mauritania"
  },
  {
    src: require("assets/img/countries/mauritania/IMG_4122.JPG"),
    altText: "North South Venture Mauritania"
  },
];

function Mauritania() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

// carousel 1 controls
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // carousel 2 controls
    const [activeIndex2, setActiveIndex2] = React.useState(0);
    const [animating2, setAnimating2] = React.useState(false);
    const onExiting2 = () => {
      setAnimating2(true);
    };
    const onExited2 = () => {
      setAnimating2(false);
    };
    const next2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === items2.length - 1 ? 0 : activeIndex2 + 1;
      setActiveIndex2(nextIndex2);
    };
    const previous2 = () => {
      if (animating2) return;
      const nextIndex2 = activeIndex2 === 0 ? items2.length - 1 : activeIndex2 - 1;
      setActiveIndex2(nextIndex2);
    };
    const goToIndex2 = newIndex2 => {
      if (animating2) return;
      setActiveIndex2(newIndex2);
    };

  let pageHeader = React.createRef();
  React.useEffect(() => {
  window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/countries/mauritania/DSC00558-1.JPG") + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Mauritania</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="cd-section" id="headers">
        <div className="section">
          <Container className="blogStart text-center">
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-2"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Morocco"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i> Morocco
                  </Button>
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    tag={Link}
                    to="/Senegal"
                  >
                  Senegal <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto mb-4" md="10">
                    <h3 className="title text-left">March 2nd, 2010</h3>
                    <p className="description text-left">
                    So our introduction to Mauritania was grim - with the path through the land mines freshly etched into our minds we rode into Nouadhibou, literally translated as the jackal's well. An appropriate name. Attempting to further strengthen our bond with the desert, we spent the night at the Auberge Sahara. We have had better nights sleep.
                    </p>
                  </Col>
                </Row>
                <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                  <CarouselIndicators
                    items={items1}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items1.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>

                <Row>
                  <Col className="mr-auto ml-auto mt-5 mb-4" md="10">
                  	<p className="description text-left">The next morning we awoke early and set out for Nouakchott after a great breakfast and good coffee. We were a little uneasy about this road after the November incident, but security seemed improved and we left early. Little did we know that the intense wind and blowing sand would be the taxing factors of the day. Those of you familiar with the art of sand blasting may be able to relate to this experience. All exposed paint on our locks, the finish on our panniers, and paint on the pannier frame - all gone by the end of the day.</p>
        						<p className="description text-left">Besides the wind, the road was in fantastic shape and it was relatively easy to keep up a good pace. We arrived at Auberge Nomades in Naoukchott after about 6 hours, tired and relieved the drive was over. The checkpoints were again hassle free. Although it would take a very dedicated hassler to be very persistent in those conditions. All in all, the police were very nice and genuinely interested that we were having a good trip and that we were not having any problems.</p>
        						<p className="description text-left">Bash's radiator seemed to be slowly leaking again, and with ample daylight left he successfully patched it again. Two for two repairs. Not bad - not that I am much help in this area.</p>
        						<p className="description text-left">After a fairly good night sleep, we headed off again, this time toward the border. We opted to cross into Senegal via Diama versus the border at Rosso. And what a good decision it was. The exit and entrance could not have been easier. The policeman's son even helped guide us through the process. He seemed somewhat bored and was excited to see someone to socialize with. Lots of hand shaking all around. I liked Senegal immediately.</p>
                  </Col>
                </Row>
                <Row>
                <Carousel activeIndex={activeIndex2} next={next2} previous={previous2}>
                  <CarouselIndicators
                    items={items2}
                    activeIndex={activeIndex2}
                    onClickHandler={goToIndex2}
                  />
                  {items2.map(item2 => {
                    return (
                      <CarouselItem
                        onExiting={onExiting2}
                        onExited={onExited2}
                        key={item2.src}
                      >
                        <img src={item2.src} alt={item2.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item2.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next2();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto mt-5" md="10">
                    <p className="description text-left">A few technical details - an 8 Euro bridge tax was all we paid. Mauritania wanted money for a "moto tax," but we again pretended not to speak French and the gentleman quickly tired in his quest and let us go. The entire ordeal on both sides took about an hour.</p>
          					<p className="description text-left">We road the final 45k to Zebrabar without incident. Worried about the "corrupt" police officer just south of St Louis we were very careful to obey all traffic laws - including signaling to pull over when directed to by the police. He was rumored to get people using that dirty trick. He pulled us over anyway, but our documents were in order and we had not broken any traffic laws so he let us go after a few minutes.</p>
          					<p className="description text-left">Actually, there was some excitement close to the border. About five minutes from the border I stopped to wait for Bash when he informed me that one of my panniers was missing. Never to be found. Someone must have picked it up. Somehow the only things I lost were my wetsuit, sleeping pad, a quarter of my library, an extra jacket, extra clothing, and some extra maps. All non-essentials. Out of all of the bad things to happen - it was not that bad. The show goes on - although the bike now looks a bit odd with only one pannier.</p>
                  </Col>
                </Row>
              </Container>
              <Container className="blogFinish text-center">
                <Row>
                  <Col md="12">
                    <div className="button-container">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Morocco"
                      >
                        <i className="now-ui-icons arrows-1_minimal-left"></i> Morocco
                      </Button>
                      <Button
                        className="btn-round mr-1"
                        color="info"
                        size="lg"
                        tag={Link}
                        to="/Senegal"
                      >
                      Senegal <i className="now-ui-icons arrows-1_minimal-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Mauritania;
